import { useTranslation } from "react-i18next";
import "./HadithsPage.scss";
import React from "react";
import hadithMoslemImage from "../../assets/images/hadiths/sahih-moslem.jpeg";
import hadithBoukhariImage from "../../assets/images/hadiths/sahih-boukhari.jpeg";
import imamMalikImage from "../../assets/images/hadiths/malik.png";
import imamAhmadImage from "../../assets/images/hadiths/imam-ahmad.png";
import altarmadhiImage from "../../assets/images/hadiths/altarmadhi.png";
import { useNavigate } from "react-router-dom";

const HadithsPage = () => {
  const { t } = useTranslation();
  const listHAdiths = [
    {
      title: "Sahih Muslim",
      titleArabic: "صحيح مسلم",
      image: hadithMoslemImage,
      url: "https://heyzine.com/flip-book/c75e5ef0bd.html",
    },
    {
      title: "Sahih Boukhari",
      image: hadithBoukhariImage,
      url: "https://heyzine.com/flip-book/1a2fec0fd9.html",
    },
    {
      title: "Imam Malik",
      image: imamMalikImage,
      url: "https://heyzine.com/flip-book/f13586b399.html",
    },
    {
      title: "Imam Ahmad",
      image: imamAhmadImage,
      url: "https://heyzine.com/flip-book/6910e1d06a.html",
    },
    {
      title: "Al Tarmadhi",
      image: altarmadhiImage,
      url: "https://heyzine.com/flip-book/b16bac1f86.html",
    },
  ];
  return (
    <div className="pages-position">
      <div className="pages-header">
        <span className="title-page">{t("hadiths.TITLE")}</span>
      </div>
      <div className="hadiths-body">
        <div className="ahadith-section">
          <h2>Ahadith</h2>
          <div className="ahadith-container">
            {listHAdiths.map((hadithBook) => (
              <CardHadith key={hadithBook.title} book={hadithBook} />
            ))}
          </div>
        </div>
        <div className="others-section">
          <h2>Others</h2>
        </div>
      </div>
    </div>
  );
};

const CardHadith = ({ book }) => {
  const navigate = useNavigate();

  return (
    <div
      className="card-hadith"
      onClick={() => navigate(book.title, { state: { url: book.url } })}
    >
      <img src={book.image} alt="" />
      <h3>{book.title}</h3>
    </div>
  );
};

export default HadithsPage;
