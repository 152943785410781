import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchListMosques } from "./mosqueThunk.ts";

interface MosqueState {
  loading: string;
  listMosques: any[];
  nextPageToken: string;
  selectedMosque: any;
  radiusGettingMosque: number;
  error: string;
}

const initialState: MosqueState = {
  loading: "succeeded",
  listMosques: [],
  nextPageToken: "",
  selectedMosque: null,
  radiusGettingMosque: 1000,
  error: "",
};

const mosqueSlice = createSlice({
  name: "mosqueSlice",
  initialState,
  reducers: {
    selectMosque(state, action: PayloadAction<any>) {
      if (typeof action.payload === "number") {
        state.selectedMosque = state.listMosques[action.payload];
      } else {
        state.selectedMosque = action.payload;
      }
    },

    setRadius(state, action: PayloadAction<any>) {
      state.radiusGettingMosque = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchListMosques.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(fetchListMosques.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.listMosques = action.payload.listMosques;
        state.nextPageToken = action.payload.nextPageToken;
      })
      .addCase(fetchListMosques.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.error.message || "";
      });
  },
});

export default mosqueSlice.reducer;
export const { selectMosque, setRadius } = mosqueSlice.actions;
