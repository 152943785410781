import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PrayerTimeState {
  loading: string;
  audioToBePaused: any;
  audioPlaying: any;
  error: string;
}

const initialState: PrayerTimeState = {
  loading: "succeeded",
  audioToBePaused: null,
  audioPlaying: null,
  error: "",
};

const adhkarSlice = createSlice({
  name: "adhkarSlice",
  initialState,
  reducers: {
    setAudioPlaying(state, action: PayloadAction<{ audioPlaying: string }>) {
      return {
        ...state,
        audioToBePaused: state.audioPlaying,
        audioPlaying: action.payload.audioPlaying,
      };
    },
  },
  extraReducers: (builder) => {},
});

export default adhkarSlice.reducer;
export const { setAudioPlaying } = adhkarSlice.actions;
