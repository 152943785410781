import { configureStore } from "@reduxjs/toolkit";
import prayerTimeReducer from "../features/prayerTime/prayerTimeSlice.ts";
import quranReducer from "../features/quran/quranSlice.ts";
import mosqueReducer from "../features/mosque/mosqueSlice.ts";
import adhkarReducer from "../features/adhkar/adhkarSlice.ts";
import localisationReducer from "../features/localisation/localisationSlice.ts";
import generalSlice from "../features/general/generalSlice.ts";

const store = configureStore({
  reducer: {
    prayerTime: prayerTimeReducer,
    quran: quranReducer,
    mosque: mosqueReducer,
    adhkarReducer,
    localisationReducer,
    generalSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
