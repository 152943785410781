import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface MosqueState {
  loading: string;
  position: any;
  error: string;
}

const initialState: MosqueState = {
  loading: "succeeded",
  position: null,
  error: "",
};

const localisationSlice = createSlice({
  name: "localisationSlice",
  initialState,
  reducers: {
    setPosition(
      state,
      action: PayloadAction<{ latitude: number; longitude: number }>
    ) {
      state.position = action.payload;
      state.error = "";
    },
    setErrorPosition(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.position = null;
    },
  },
});

export default localisationSlice.reducer;
export const { setPosition, setErrorPosition } = localisationSlice.actions;
