import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./PolitiqueConfidentialite.scss";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Footer from "../../components/layout/Footer";

const PolitiqueConfidentialite = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  return (
    <div className="pages-position">
      <div className="pages-header">
        <FontAwesomeIcon
          icon={faArrowLeft}
          className={i18n.language === "ar" ? "icon-ar icon" : "icon"}
          onClick={() => {
            navigate(-1);
          }}
        />
        <span className="title-page">
          {t("politique_confidentialite.TITLE")}
        </span>
      </div>

      <div className="politique-confidentialite-body">
        {/* Section 1 - Introduction */}
        <section>
          <h2>{t("politique_confidentialite.introduction.Section")}</h2>

          <p>{t("politique_confidentialite.introduction.text")}</p>
        </section>

        {/* Section 2 - Informations Collectées */}
        <section>
          <h2>
            {t("politique_confidentialite.informations_collectees.Section")}
          </h2>

          {/* Données Volontaires */}
          <h3>
            {t(
              "politique_confidentialite.informations_collectees.volontaire.title"
            )}
          </h3>
          <ul>
            <li>
              {t(
                "politique_confidentialite.informations_collectees.volontaire.Subtitle_1"
              )}
            </li>
            <li>
              {t(
                "politique_confidentialite.informations_collectees.volontaire.Subtitle_2"
              )}
            </li>
          </ul>

          {/* Données Automatiques */}
          <h3>
            {t(
              "politique_confidentialite.informations_collectees.automatique.title"
            )}
          </h3>
          <ul>
            <li>
              {t(
                "politique_confidentialite.informations_collectees.automatique.Subtitle_1"
              )}
            </li>
            <li>
              {t(
                "politique_confidentialite.informations_collectees.automatique.Subtitle_2"
              )}
            </li>
          </ul>
        </section>

        {/* Section 3 - Utilisation des Informations */}
        <section>
          <h2>
            {t("politique_confidentialite.utilisation_informations.Section")}
          </h2>
          <p>{t("politique_confidentialite.utilisation_informations.text")}</p>
          <ul>
            <li>
              {t(
                "politique_confidentialite.utilisation_informations.Subtitle_1"
              )}
            </li>
            <li>
              {t(
                "politique_confidentialite.utilisation_informations.Subtitle_2"
              )}
            </li>
            <li>
              {t(
                "politique_confidentialite.utilisation_informations.Subtitle_3"
              )}
            </li>
            <li>
              {t(
                "politique_confidentialite.utilisation_informations.Subtitle_4"
              )}
            </li>
          </ul>
        </section>

        {/* Section 4 - Partage des Informations */}
        <section>
          <h2>{t("politique_confidentialite.partage_informations.Section")}</h2>

          <p>{t("politique_confidentialite.partage_informations.text")}</p>
          <ul>
            <li>
              {t("politique_confidentialite.partage_informations.Subtitle_1")}
            </li>
            <li>
              {t("politique_confidentialite.partage_informations.Subtitle_2")}
            </li>
          </ul>
        </section>

        {/* Section 5 - Sécurité des Informations */}
        <section>
          <h2>
            {t("politique_confidentialite.securite_informations.Section")}
          </h2>
          <p>{t("politique_confidentialite.securite_informations.text")}</p>

          <p>{t("politique_confidentialite.securite_informations.text_2")}</p>
        </section>

        {/* Section 6 - Droits des Utilisateurs */}
        <section>
          <h2>{t("politique_confidentialite.droits_utilisateur.Section")}</h2>

          <p>{t("politique_confidentialite.droits_utilisateur.text")}</p>
          <ul>
            <li>
              {t("politique_confidentialite.droits_utilisateur.Subtitle_1")}
            </li>
            <li>
              {t("politique_confidentialite.droits_utilisateur.Subtitle_2")}
            </li>
            <li>
              {t("politique_confidentialite.droits_utilisateur.Subtitle_3")}
            </li>
            <li>
              {t("politique_confidentialite.droits_utilisateur.Subtitle_4")}
            </li>
          </ul>
          <p>{t("politique_confidentialite.droits_utilisateur.text_2")}</p>
        </section>

        {/* Section 7 - Modifications */}
        <section>
          <h2>{t("politique_confidentialite.modifications.Section")}</h2>

          <p>{t("politique_confidentialite.modifications.text")}</p>
        </section>

        {/* Section 8 - Contact */}
        <section>
          <h2>{t("politique_confidentialite.contact.Section")}</h2>

          <p>{t("politique_confidentialite.contact.text")}</p>
        </section>
      </div>

      <Footer />
    </div>
  );
};

export default PolitiqueConfidentialite;
