import "./WudhuSteps.scss";
import React, { useState } from "react";
import step1W from "../../../../assets/images/wudhu/1.png";
import step2W from "../../../../assets/images/wudhu/2.png";
import step3W from "../../../../assets/images/wudhu/3.png";
import step4W from "../../../../assets/images/wudhu/4.png";
import step5W from "../../../../assets/images/wudhu/5.png";
import step6W from "../../../../assets/images/wudhu/6.png";
import step7W from "../../../../assets/images/wudhu/7.png";
import step8W from "../../../../assets/images/wudhu/8.png";
import step9W from "../../../../assets/images/wudhu/9.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnglesRight,
  faAnglesLeft,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Footer from "../../../../components/layout/Footer";

const WUDHU_STEPS = [
  {
    title: "learn.WUDHU_GHOUSL.WUDHU_PAGE.STEP_1_TITLE",
    image: step1W,
    description: "learn.WUDHU_GHOUSL.WUDHU_PAGE.STEP_1_DESCRIPTION",
  },
  {
    title: "learn.WUDHU_GHOUSL.WUDHU_PAGE.STEP_2_TITLE",
    image: step2W,
    description: "learn.WUDHU_GHOUSL.WUDHU_PAGE.STEP_2_DESCRIPTION",
  },
  {
    title: "learn.WUDHU_GHOUSL.WUDHU_PAGE.STEP_3_TITLE",
    image: step3W,
    description: "learn.WUDHU_GHOUSL.WUDHU_PAGE.STEP_3_DESCRIPTION",
  },
  {
    title: "learn.WUDHU_GHOUSL.WUDHU_PAGE.STEP_4_TITLE",
    image: step4W,
    description: "learn.WUDHU_GHOUSL.WUDHU_PAGE.STEP_4_DESCRIPTION",
  },
  {
    title: "learn.WUDHU_GHOUSL.WUDHU_PAGE.STEP_5_TITLE",
    image: step5W,
    description: "learn.WUDHU_GHOUSL.WUDHU_PAGE.STEP_5_DESCRIPTION",
  },
  {
    title: "learn.WUDHU_GHOUSL.WUDHU_PAGE.STEP_6_TITLE",
    image: step6W,
    description: "learn.WUDHU_GHOUSL.WUDHU_PAGE.STEP_6_DESCRIPTION",
  },
  {
    title: "learn.WUDHU_GHOUSL.WUDHU_PAGE.STEP_7_TITLE",
    image: step7W,
    description: "learn.WUDHU_GHOUSL.WUDHU_PAGE.STEP_7_DESCRIPTION",
  },
  {
    title: "learn.WUDHU_GHOUSL.WUDHU_PAGE.STEP_8_TITLE",
    image: step8W,
    description: "learn.WUDHU_GHOUSL.WUDHU_PAGE.STEP_8_DESCRIPTION",
  },
  {
    title: "learn.WUDHU_GHOUSL.WUDHU_PAGE.STEP_9_TITLE",
    image: step9W,
    description: "learn.WUDHU_GHOUSL.WUDHU_PAGE.STEP_9_DESCRIPTION",
  },
];

export default function WudhuSteps() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [stepWudhu, setStepWudhu] = useState(1);

  return (
    <div className="pages-position">
      <div className="pages-header">
        <FontAwesomeIcon
          icon={faArrowLeft}
          className={i18n.language === "ar" ? "icon-ar icon" : "icon"}
          onClick={() => {
            navigate(-1);
          }}
        />
        <span className="title-page">
          {t("learn.WUDHU_GHOUSL.WUDHU_PAGE.TITLE")}
        </span>
      </div>
      <div className="wudhu-body">
        <StepWudhu stepWudhu={WUDHU_STEPS[stepWudhu - 1]}></StepWudhu>
        <div className="flex items-center min-600-screen:mt-10 justify-between">
          {stepWudhu > 1 ? (
            <div
              onClick={() => setStepWudhu((currentStep) => currentStep - 1)}
              className="cursor-pointer"
            >
              <FontAwesomeIcon
                icon={i18n.language === "ar" ? faAnglesRight : faAnglesLeft}
                className="icon text-green"
              />
              <span className="text-base xl:text-xl md:text-lg text-green font-bold">
                {t("common.PREVIOUS")}
              </span>
            </div>
          ) : (
            <div></div>
          )}
          {stepWudhu < WUDHU_STEPS.length && (
            <div
              onClick={() => setStepWudhu((currentStep) => currentStep + 1)}
              className="cursor-pointer"
            >
              <span className="text-base xl:text-xl md:text-lg text-green font-bold">
                {t("common.NEXT")}
              </span>
              <FontAwesomeIcon
                icon={i18n.language === "ar" ? faAnglesLeft : faAnglesRight}
                className="icon"
              />
            </div>
          )}
        </div>
        {/* stepper */}
        <div className="flex flex-col items-center stepper-ghousl">
          <span className="mb-2 font-bold">
            {t("common.STEP", {
              step: stepWudhu,
              totalSteps: WUDHU_STEPS.length,
            })}
          </span>

          <input
            type="range"
            value={
              WUDHU_STEPS.length
                ? stepWudhu > WUDHU_STEPS.length
                  ? 100
                  : (stepWudhu / WUDHU_STEPS.length) * 100
                : 0
            }
            className={
              "rounded-lg overflow-hidden appearance-none h-3 w-128 bar-stepper " +
              (i18n.language === "ar" ? "arabic-bar" : "")
            }
          />
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

const StepWudhu = ({ stepWudhu }) => {
  const { t } = useTranslation();

  return (
    <div className="step-wudhu">
      <img src={stepWudhu.image} alt="" />
      <div className="step-wudhu-details">
        <h1>{t(stepWudhu.title)}</h1>
        <p>{t(stepWudhu.description)}</p>
      </div>
    </div>
  );
};
