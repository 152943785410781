import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import "./ConditionsGenerales.scss";
import Footer from "../../components/layout/Footer";

const ConditionsGenerales = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  return (
    <div className="pages-position">
      <div className="pages-header">
        <FontAwesomeIcon
          icon={faArrowLeft}
          className={i18n.language === "ar" ? "icon-ar icon" : "icon"}
          onClick={() => {
            navigate(-1);
          }}
        />
        <span className="title-page">{t("conditions_generales.TITLE")}</span>
      </div>
      <div className="conditions-generales-body">
        {/* Section Introduction */}
        <div>
          <h2>{t("conditions_generales.INTRODUCTION.TITLE")}</h2>
          <p>{t("conditions_generales.INTRODUCTION.TEXT")}</p>
        </div>

        {/* Section Description des services */}
        <div>
          <h2>{t("conditions_generales.DESCRIPTION_SERVICES.TITLE")}</h2>
          <p>{t("conditions_generales.DESCRIPTION_SERVICES.TEXT")}</p>

          {/* Heures de Prière */}
          <div>
            <h3>
              {t(
                "conditions_generales.DESCRIPTION_SERVICES.SERVICE.HEURES_DE_PRIERE.TITLE"
              )}
            </h3>
            <ul>
              <li>
                {t(
                  "conditions_generales.DESCRIPTION_SERVICES.SERVICE.HEURES_DE_PRIERE.DESCRIPTION_1"
                )}
              </li>
              <li>
                {t(
                  "conditions_generales.DESCRIPTION_SERVICES.SERVICE.HEURES_DE_PRIERE.DESCRIPTION_2"
                )}
              </li>
              <li>
                {t(
                  "conditions_generales.DESCRIPTION_SERVICES.SERVICE.HEURES_DE_PRIERE.DESCRIPTION_3"
                )}
              </li>
            </ul>
          </div>

          {/* Piliers de l'Islam */}
          <div>
            <h3>
              {t(
                "conditions_generales.DESCRIPTION_SERVICES.SERVICE.PILIERS_LISLAM.TITLE"
              )}
            </h3>
            <ul>
              <li>
                {t(
                  "conditions_generales.DESCRIPTION_SERVICES.SERVICE.PILIERS_LISLAM.DESCRIPTION_1"
                )}
              </li>
              <li>
                {t(
                  "conditions_generales.DESCRIPTION_SERVICES.SERVICE.PILIERS_LISLAM.DESCRIPTION_2"
                )}
              </li>
            </ul>
          </div>

          {/* Récitation du Coran */}
          <div>
            <h3>
              {t(
                "conditions_generales.DESCRIPTION_SERVICES.SERVICE.RECITATION_CORAN.TITLE"
              )}
            </h3>
            <ul>
              <li>
                {t(
                  "conditions_generales.DESCRIPTION_SERVICES.SERVICE.RECITATION_CORAN.DESCRIPTION_1"
                )}
              </li>
              <li>
                {t(
                  "conditions_generales.DESCRIPTION_SERVICES.SERVICE.RECITATION_CORAN.DESCRIPTION_2"
                )}
              </li>
              <li>
                {t(
                  "conditions_generales.DESCRIPTION_SERVICES.SERVICE.RECITATION_CORAN.DESCRIPTION_3"
                )}
              </li>
            </ul>
            <ul>
              <li>
                {t(
                  "conditions_generales.DESCRIPTION_SERVICES.SERVICE.RECITATION_CORAN.DESCRIPTION_4"
                )}
              </li>
            </ul>
          </div>

          {/* Localisation des Mosquées */}
          <div>
            <h3>
              {t(
                "conditions_generales.DESCRIPTION_SERVICES.SERVICE.LOCALISATION_MOSQUES.TITLE"
              )}
            </h3>
            <ul>
              <li>
                {t(
                  "conditions_generales.DESCRIPTION_SERVICES.SERVICE.LOCALISATION_MOSQUES.DESCRIPTION_1"
                )}
              </li>
              <li>
                {t(
                  "conditions_generales.DESCRIPTION_SERVICES.SERVICE.LOCALISATION_MOSQUES.DESCRIPTION_2"
                )}
              </li>
            </ul>
          </div>

          {/* Dhikr & Dua */}
          <div>
            <h3>
              {t(
                "conditions_generales.DESCRIPTION_SERVICES.SERVICE.DHIKR_DUA.TITLE"
              )}
            </h3>
            <ul>
              <li>
                {t(
                  "conditions_generales.DESCRIPTION_SERVICES.SERVICE.DHIKR_DUA.DESCRIPTION_1"
                )}
              </li>
              <li>
                {t(
                  "conditions_generales.DESCRIPTION_SERVICES.SERVICE.DHIKR_DUA.DESCRIPTION_2"
                )}
              </li>
              <li>
                {t(
                  "conditions_generales.DESCRIPTION_SERVICES.SERVICE.DHIKR_DUA.DESCRIPTION_3"
                )}
              </li>
            </ul>
          </div>

          {/* Instructions pratiques */}
          <div>
            <h3>
              {t(
                "conditions_generales.DESCRIPTION_SERVICES.SERVICE.INSTRUCTIONS_PRATIQUES.TITLE"
              )}
            </h3>
            <ul>
              <li>
                {t(
                  "conditions_generales.DESCRIPTION_SERVICES.SERVICE.INSTRUCTIONS_PRATIQUES.DESCRIPTION_1"
                )}
              </li>
              <li>
                {t(
                  "conditions_generales.DESCRIPTION_SERVICES.SERVICE.INSTRUCTIONS_PRATIQUES.DESCRIPTION_2"
                )}
              </li>
              <li>
                {t(
                  "conditions_generales.DESCRIPTION_SERVICES.SERVICE.INSTRUCTIONS_PRATIQUES.DESCRIPTION_3"
                )}
              </li>
            </ul>
          </div>
        </div>

        {/* Utilisation du site */}
        <div>
          <h2>{t("conditions_generales.UTILISATION_SITE.TITLE")}</h2>
          <ul>
            <li>{t("conditions_generales.UTILISATION_SITE.DESCRIPTION_1")}</li>
            <li>{t("conditions_generales.UTILISATION_SITE.DESCRIPTION_2")}</li>
            <li>{t("conditions_generales.UTILISATION_SITE.DESCRIPTION_3")}</li>
          </ul>
        </div>

        {/* Contenu utilisateur */}
        <div>
          <h2>{t("conditions_generales.CONTENU_UTILISATEUR.TITLE")}</h2>
          <ul>
            <li>
              {t("conditions_generales.CONTENU_UTILISATEUR.DESCRIPTION_1")}
            </li>
            <li>
              {t("conditions_generales.CONTENU_UTILISATEUR.DESCRIPTION_2")}
            </li>
          </ul>
        </div>

        {/* Protection des données */}
        <div>
          <h2>{t("conditions_generales.PROTECTION_DES_DONNEES.TITLE")}</h2>
          <p>{t("conditions_generales.PROTECTION_DES_DONNEES.TEXT")}</p>
        </div>

        {/* Limitation de responsabilité */}
        <div>
          <h2>{t("conditions_generales.LIMITATION_RESPONSABILITE.TITLE")}</h2>
          <p>{t("conditions_generales.LIMITATION_RESPONSABILITE.TEXT")}</p>
        </div>

        {/* Modifications des CGU */}
        <div>
          <h2>{t("conditions_generales.MODIFICATIONS_CGU.TITLE")}</h2>
          <p>{t("conditions_generales.MODIFICATIONS_CGU.TEXT")}</p>
        </div>

        {/* Loi applicable et juridiction */}
        <div>
          <h2>{t("conditions_generales.LOI_APPLICABLE_JURIDICTION.TITLE")}</h2>
          <p>{t("conditions_generales.LOI_APPLICABLE_JURIDICTION.TEXT")}</p>
        </div>

        {/* Contact */}
        <div>
          <h2>{t("conditions_generales.CONTACT.TITLE")}</h2>
          <p>{t("conditions_generales.CONTACT.TEXT")}</p>
        </div>
      </div>

      <Footer />
    </div>
  );
};
export default ConditionsGenerales;
