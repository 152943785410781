import "./AboutUsPage.scss";
import { useTranslation } from "react-i18next";
import Footer from "../../components/layout/Footer";

const AboutUsPage = () => {
  const { t } = useTranslation();
  return (
    <div className="pages-position">
      <div className="pages-header">
        <span className="title-page">{t("about.TITLE")}</span>
      </div>
      <div className="about-body">
        <p>
          Muslim Community est une plateforme dédiée à la communauté musulmane
          et à ceux qui s'intéressent à l'islam.
        </p>
        <div>
          <h2>Notre Mission</h2>
          <p>
            Muslim Community est une plateforme engagée à fournir des ressources
            et des services complets pour soutenir et enrichir la vie des
            musulmans à travers le monde. Notre mission est de créer un espace
            où les individus peuvent se connecter, apprendre et grandir
            spirituellement tout en respectant les principes de l'Islam.
          </p>
        </div>
        <div>
          <h2>Notre Vision</h2>
          <p>
            Nous envisageons une communauté mondiale unie par la foi, où chacun
            peut accéder à des connaissances islamiques authentiques, se sentir
            soutenu dans ses pratiques religieuses et établir des relations
            éthiques et enrichissantes. Nous aspirons à être la référence en
            matière de ressources islamiques en ligne, offrant des outils et des
            services adaptés aux besoins modernes.
          </p>
        </div>

        <div>
          <h2>Nos valeurs</h2>
          <ul>
            <li>
              <span className="font-bold">Foi : </span>
              <span>
                Mettre l'accent sur la pratique et l'enseignement des principes
                islamiques.
              </span>
            </li>
            <li>
              <span className="font-bold">Unité : </span>
              <span>
                Favoriser un sentiment d'appartenance et de solidarité au sein
                de la communauté.
              </span>
            </li>
            <li>
              <span className="font-bold">Éducation : </span>
              <span>
                Promouvoir l'apprentissage continu et l'accès à des
                connaissances fiables et authentiques.
              </span>
            </li>
            <li>
              <span className="font-bold">Respect : </span>
              <span>
                Encourager des interactions respectueuses et bienveillantes,
                fondées sur les valeurs de l'Islam.
              </span>
            </li>
          </ul>
        </div>

        <div>
          <h2>Nos Services</h2>
          <p className="">
            Muslim Community propose une gamme variée de services pour répondre
            aux besoins de la communauté :
          </p>
          <ul>
            <li>
              <span className="font-bold">Cours Islamiques :</span>
              <span>
                Accédez à une vaste bibliothèque de cours islamiques en ligne,
                couvrant divers aspects de la foi et de la jurisprudence
                islamique.
              </span>
            </li>
            <li>
              <span className="font-bold">Piliers de l'Islam : </span>
              <span>
                Comprenez et pratiquez les fondements de votre foi avec nos
                ressources sur les Piliers de l'Islam.
              </span>
            </li>
            <li>
              <span className="font-bold">Écoute du Coran :</span>
              <span>
                Récitez et écoutez le Saint Coran en ligne avec des traductions
                et des commentaires détaillés.
              </span>
            </li>
            <li>
              <span className="font-bold">Localisation des Mosquées : </span>
              <span>
                Trouvez et localisez les mosquées près de chez vous avec des
                informations complètes et des itinéraires pratiques.
              </span>
            </li>
            <li>
              <span className="font-bold">Dhikr & Dua : </span>
              <span>
                Accédez à une collection de Dhikr et Dua pour nourrir votre
                spiritualité et vos prières quotidiennes.
              </span>
            </li>
            <li>
              <span className="font-bold">Relations Halal : </span>
              <span>
                Nous facilitons les rencontres et les connexions entre personnes
                cherchant à établir des relations tout en respectant strictement
                les principes de l'Islam et les normes du halal.
              </span>
            </li>
            <li>
              <span className="font-bold">Services Islamiques : </span>
              <span>
                Découvrez une gamme de services islamiques, y compris les
                cérémonies religieuses, les heures de prière, et bien plus
                encore.
              </span>
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default AboutUsPage;
