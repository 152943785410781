import Footer from "../../components/layout/Footer";
import { PRAYERS_LIST } from "../../constants/salat.constants.ts";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./SalahPage.module.scss";
import { useTranslation } from "react-i18next";

function SalahPage() {
  const [hovered, setHovered] = useState(null);
  const { t } = useTranslation();

  return (
    <div className="pages-position">
      <div className="pages-header">
        <span className="title-page"> {t("learn.SALAT.SUB_TITLE")}</span>
      </div>

      <h1 className="font-bold text-blackBlue min-700-screen:mb-2 p-4 mx-10 min-700-screen:mx-20 mt-10">
        {t("learn.SALAT.OBLIGATORY_SALAT")}
      </h1>
      <div className="flex flex-col items-center justify-center">
        <p className="text-justify px-4 text-center mb-10 min-700-screen:mb-20 mx-10 min-700-screen:mx-20">
          {t("learn.SALAT.DESCRIPTION")}
        </p>

        {/* image+name */}
        <div className={`${styles["prayers-list"]}`}>
          {PRAYERS_LIST.map((prayer, index) => (
            <Link
              key={index}
              to={`${prayer.name.toLowerCase()}`}
              onMouseEnter={() => setHovered(index)}
              onMouseLeave={() => setHovered(null)}
              className={`${styles.prayer} flex flex-col items-center justify-center`}
            >
              <img
                src={prayer.image}
                alt={t(prayer.title)}
                className={`imageSalet duration-200 ${
                  hovered === index ? "scale-125" : "scale-100"
                }`}
              />
              <span className="mt-2 min-700-screen:mt-6 text-center">
                {t(prayer.title)}
              </span>
            </Link>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default SalahPage;
