import { useEffect, useState } from "react";
import "./AudioPlayer.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlay, faCirclePause } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { formatTime } from "../../../utils/helpers";
import { handlePositionBarTime } from "../../../services/quran.service.ts";
import { useAppDispatch, useAppSelector } from "../../../app/hooks.ts";
import { setAudioPlaying } from "../../../features/adhkar/adhkarSlice.ts";
import Spinner from "../spinner/Spinner.tsx";

const AudioPlayer = ({
  audioUrl,
  colorInput,
  withBorder,
  stopAudio,
  setStopAudio,
  fromAdhkar,
}) => {
  const [playing, setPlaying] = useState(false);
  const [duration, setDuration] = useState("0:00");
  const [currentTime, setCurrentTime] = useState("0:00");
  const [currentPositionBarTime, setCurrentPositionBarTime] = useState(0);
  const [countTimeInterval, setCountTimeInterval] = useState(null);
  const [audioLoaded, setAudioLoaded] = useState(false);

  const audioToBePaused = useAppSelector(
    (state) => state.adhkarReducer.audioToBePaused
  );
  const dispatch = useAppDispatch();

  const handleAudio = () => {
    setPlaying(!playing);
    const audioElement = document.getElementById(audioUrl);
    if (audioElement) {
      // setDuration(formatTime(audioElement.duration));
      if (!playing) {
        // if the user click to display the audio
        if (audioElement.currentTime >= audioElement.duration) {
          setCurrentTime("0:00");
        }
        audioElement.play();

        const interval = setInterval(() => {
          setCurrentTime((time) =>
            moment(time, "m:ss").add(1, "seconds").format("m:ss")
          );
          setCurrentPositionBarTime(
            () => (audioElement.currentTime / audioElement.duration) * 100
          );
          // if the audio is finished
          if (audioElement.currentTime >= audioElement.duration) {
            clearInterval(interval);
            setPlaying(false);
            setCurrentTime("0:00");
            setCurrentPositionBarTime(0);
          }
        }, 1000);
        setCountTimeInterval(interval);
        if (fromAdhkar) {
          dispatch(setAudioPlaying({ audioPlaying: audioUrl }));
        }
      } else {
        clearInterval(countTimeInterval);
        audioElement.pause();
      }
    }
  };

  useEffect(() => {
    setAudioLoaded(false);
    const audioElement = document.getElementById(audioUrl);
    // to listen if the audio has been loaded
    audioElement.addEventListener("canplaythrough", function (ev) {
      setDuration(formatTime(ev.target?.duration));
      setAudioLoaded(true);
    });
  }, [audioUrl]);

  useEffect(() => {
    if (stopAudio) {
      const audioElement = document.getElementById(audioUrl);
      audioElement.pause();
      setPlaying(false);
      clearInterval(countTimeInterval);
      setStopAudio(false);
    }
  }, [stopAudio]);

  useEffect(() => {
    // to pause the audio in the page adhkar if othe audio is opened
    if (audioToBePaused === audioUrl) {
      const audioElement = document.getElementById(audioUrl);
      audioElement.pause();
      setPlaying(false);
      clearInterval(countTimeInterval);
    }
  }, [audioToBePaused]);

  return (
    <div className={"audio-player " + (withBorder && "withBorder")}>
      <audio src={audioUrl} id={audioUrl}></audio>
      <button className="play" onClick={handleAudio}>
        {!audioLoaded ? (
          <Spinner />
        ) : (
          <FontAwesomeIcon
            icon={!playing ? faCirclePlay : faCirclePause}
            className={"icon " + (!playing ? "faCirclePlay" : "faCirclePause")}
          />
        )}
      </button>
      <input
        className={
          "rounded-lg overflow-hidden appearance-none w-128 " +
          (withBorder ? "h-2" : "h-3")
        }
        style={{ backgroundColor: colorInput || "var(--back-gray-2)" }}
        type="range"
        id="seek-bar"
        value={currentPositionBarTime}
        onChange={(event) =>
          handlePositionBarTime(
            audioUrl,
            event.target.value,
            duration,
            setCurrentTime,
            setCurrentPositionBarTime
          )
        }
      />
      <span>{currentTime}</span> /<span>{duration}</span>
    </div>
  );
};

export default AudioPlayer;
