import "./RakaaPage.scss";
import Footer from "../../components/layout/Footer";
import { PRAYERS_LIST, RAKAA_STEPS } from "../../constants/salat.constants.ts";
import back2 from "../../assets/salahIcons/ButtonLeft2.svg";
import forward from "../../assets/salahIcons/ButtonRight.svg";
import React, { useEffect, useState } from "react";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const RakaaPage = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { prayer } = useParams();
  const steps = PRAYERS_LIST.find(
    (el) => el.name.toLowerCase() === prayer.toLowerCase()
  )?.steps;
  const [screenWidth, setScreenWidth] = useState(0);
  const [selectedStep, setSelectedStep] = useState(0);

  useEffect(() => {
    setScreenWidth(window.innerWidth);
    window.addEventListener("resize", () => setScreenWidth(window.innerWidth));
  }, []);

  const previousSlide = () => {
    setCurrentSlide((current) => {
      const previous = current === 0 ? RAKAA_STEPS.length - 1 : current - 1;
      const selectedRakaaIndex = steps.findIndex((el) => el.index === previous);
      if (selectedRakaaIndex !== -1) {
        setSelectedStep(selectedRakaaIndex);
      }
      return previous;
    });
  };

  const nextSlide = () => {
    setCurrentSlide((current) => {
      const next = current === RAKAA_STEPS.length - 1 ? 0 : current + 1;
      const selectedRakaaIndex = steps.findIndex((el) => el.index === next);
      if (selectedRakaaIndex !== -1) {
        setSelectedStep(selectedRakaaIndex);
      }
      return next;
    });
  };

  const getNextIndex = () => {
    return (currentSlide + 1) % RAKAA_STEPS.length;
  };

  return (
    <div className="pages-position">
      <div className="pages-header">
        <FontAwesomeIcon
          icon={faArrowLeft}
          className={i18n.language === "ar" ? "icon-ar icon" : "icon"}
          onClick={() => {
            navigate(-1);
          }}
        />
        <span className="title-page">{t("learn.SALAT.RAKAA.TITLE")}</span>
      </div>
      <div className="rakaa-body">
        <div className="flex flex-col justify-center items-center">
          <div className="all flex max-w-screen-xl">
            {/* steps salat */}
            <div className="flex flex-col text-center list-steps">
              <h1>{t(`learn.SALAT.${prayer?.toLowerCase()}.TITLE`)}</h1>
              <div className="steps-container">
                {steps?.map((step, index) => (
                  <StepSalat
                    step={step}
                    numStep={index + 1}
                    key={index}
                    setCurrentSlide={setCurrentSlide}
                    selectedStep={selectedStep}
                    setSelectedStep={setSelectedStep}
                    screenWidth={screenWidth}
                  />
                ))}
              </div>
            </div>

            {/* Current Slide */}
            {screenWidth > 750 && (
              <div className="flex flex-col items-center">
                <div className="current flex flex-col rakaa-selected text-center">
                  <img
                    src={RAKAA_STEPS[currentSlide].image}
                    alt="current slide"
                    className="mx-auto w-56"
                  />
                  <h2>{t(RAKAA_STEPS[currentSlide].title)}</h2>
                  <p className="text-justify">
                    {t(RAKAA_STEPS[currentSlide].description)}
                  </p>
                </div>
                {/* buttons left and right */}
                <div className="flex mt-8 gap-6">
                  {/* left button */}
                  <div>
                    <button
                      onClick={previousSlide}
                      className="abolute btn-arrow"
                    >
                      <img
                        src={i18n.language === "ar" ? forward : back2}
                        alt="forward"
                      ></img>
                    </button>
                  </div>
                  {/* Right button */}
                  <div>
                    <button onClick={nextSlide} className="abolute btn-arrow">
                      <img
                        src={i18n.language === "ar" ? back2 : forward}
                        alt="forward"
                      ></img>
                    </button>
                  </div>
                </div>
              </div>
            )}

            {/* Next Slide */}
            {screenWidth > 1000 && (
              <div className="card-prayerRight flex flex-col p-4 w-1/5 text-center">
                <img
                  src={RAKAA_STEPS[getNextIndex()].image}
                  alt="next slide"
                  className="mx-auto w-24"
                />
                <h2>{t(RAKAA_STEPS[getNextIndex()].title)}</h2>

                <p className="special text-xs text-justify">
                  {t(RAKAA_STEPS[getNextIndex()].description)}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const StepSalat = ({
  step,
  numStep,
  setCurrentSlide,
  selectedStep,
  setSelectedStep,
  screenWidth,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <div
        className={
          "step-salat " + (selectedStep === numStep - 1 ? "selected-step" : "")
        }
        onClick={() => {
          setCurrentSlide(step.index);
          setSelectedStep(numStep - 1);
        }}
      >
        <div className="num-step-salat">
          <span>{numStep}</span>
        </div>
        <div
          className="title-step-salat"
          style={{
            transform:
              i18n.language === "ar"
                ? "translateX(1.4em)"
                : "translateX(-1.4em)",
          }}
        >
          <h1>{t(`learn.SALAT.RAKAA.${step?.title}`)}</h1>
        </div>
      </div>
      {screenWidth < 750 && selectedStep === numStep - 1 ? (
        <div className="details-step">
          <div className="my-6 flex flex-col text-center">
            <img
              src={RAKAA_STEPS[step.index].image}
              alt="slide"
              className="mx-auto w-28"
            />
            <p className="text-justify">
              {t(RAKAA_STEPS[step.index].description)}
            </p>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default RakaaPage;
