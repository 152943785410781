import "./NamesOfAllah.scss";
import namesOfAllahImage from "../../assets/images/names-of-allah.svg";
import soundLogo from "../../assets/logos/sound.svg";
import Footer from "../../components/layout/Footer";
import useSound from "use-sound";
import AudioPlayer from "../../components/common/audioPlayer/AudioPlayer";
import { GET_NAMES_OF_ALLAH_AUDIO } from "../../constants/apis.constants.ts";
import { useEffect, useState } from "react";
import { getListNamesOfAllah } from "../../services/common.service.ts";
import Loader from "../../components/common/Loader/Loader.jsx";

const NamesOfAllah = () => {
  const [stopAudio, setStopAudio] = useState(false);
  const [namesOfAllahData, setNamesOfAllahData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getListNamesOfAllah()
      .then((result) => {
        setNamesOfAllahData(result);
        setLoading(false);
      })
      .catch((_err) => {
        setLoading(false);
      });
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <div className="pages-position">
      <div className="allah-names-header">
        <img src={namesOfAllahImage} alt="" />
        <div className="audio-allah-names">
          <AudioPlayer
            audioUrl={GET_NAMES_OF_ALLAH_AUDIO}
            withBorder={true}
            colorInput={"rgb(217 215 206)"}
            stopAudio={stopAudio}
            setStopAudio={setStopAudio}
          ></AudioPlayer>
        </div>
      </div>
      <div className="allah-names-body">
        <div className="allah-names-container">
          {namesOfAllahData.map((name) => (
            <CardNameAllah
              name={name}
              key={name._id}
              setStopAudio={setStopAudio}
            />
          ))}
        </div>
        <Footer />
      </div>
    </div>
  );
};

const CardNameAllah = ({ name, setStopAudio }) => {
  const [play] = useSound(name.url);
  return (
    <div
      className="card-name-allah"
      onClick={() => {
        setStopAudio(true);
        play();
      }}
    >
      <div className="arabic-name">
        <span>{name.arabic}</span>
      </div>
      <div className="name-pronunciation">
        <span>{name.pronunciation}</span>
      </div>
      <div className="name-meaning">
        <span>{name.french}</span>
      </div>
      <img src={soundLogo} alt="" />
    </div>
  );
};

export default NamesOfAllah;
