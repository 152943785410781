import { useEffect, useState } from "react";
import Calendar from "react-calendar";
import React from "react";
import "./MyCalendar.scss";
import { useAppDispatch, useAppSelector } from "../../../app/hooks.ts";
import { fetchPrayerTimes } from "../../../features/prayerTime/prayerTimeThunk.ts";
import moment from "moment";
import { useTranslation } from "react-i18next";

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

export function MyCalendar({ hijriCalendar }) {
  const dispatch = useAppDispatch();
  const [value, onChange] = useState<Value>(new Date());
  const { i18n } = useTranslation();
  const position = useAppSelector(
    (state) => state.localisationReducer.position
  );

  useEffect(() => {
    if (position) {
      dispatch(
        fetchPrayerTimes({ position, date: moment().format("DD-MM-YYYY") })
      );
    }
  }, [position]);

  const onChangeDate = (value) => {
    onChange(value);
    if (position) {
      dispatch(
        fetchPrayerTimes({
          position,
          date: moment(value).format("DD-MM-YYYY"),
        })
      );
    }
  };

  const formatDate = (type, date): string => {
    switch (type) {
      case "day":
        return new Intl.DateTimeFormat(`${i18n.language}-TN-u-ca-islamic`, {
          day: "numeric",
        }).format(date);

      case "year-month":
        return new Intl.DateTimeFormat(`${i18n.language}-TN-u-ca-islamic`, {
          month: "long",
          year: "numeric",
        }).format(new Date(date));

      case "year":
        return new Intl.DateTimeFormat(`${i18n.language}-TN-u-ca-islamic`, {
          year: "numeric",
        }).format(date);

      case "month":
        return new Intl.DateTimeFormat(`${i18n.language}-TN-u-ca-islamic`, {
          month: "long",
        }).format(date);

      default:
        return "";
    }
  };

  return (
    <div>
      {hijriCalendar ? (
        <Calendar
          onChange={onChangeDate}
          value={value}
          formatDay={(_locale, date) => formatDate("day", date)}
          formatMonthYear={(_locale, _date) => formatDate("year-month", value)}
          formatYear={(_locale, date) => formatDate("year", date)}
          formatMonth={(_locale, date) => formatDate("month", date)}
          showFixedNumberOfWeeks={true}
          onClickMonth={onChangeDate}
          onActiveStartDateChange={(data) =>
            onChangeDate(data?.activeStartDate)
          }
        />
      ) : (
        <Calendar
          onChange={onChangeDate}
          value={value}
          showFixedNumberOfWeeks={true}
        />
      )}
    </div>
  );
}
