import { useTranslation } from "react-i18next";
import "./MosquePage.scss";
import MyGoogleMap from "../../components/specific/googleMap/MyGoogleMap.tsx";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks.ts";
import { fetchListMosques } from "../../features/mosque/mosqueThunk.ts";
import positionIconWhite from "../../assets/icons/position-white.svg";
import positionIcon from "../../assets/icons/position.svg";
import { removeDiacritics, removeSpaces } from "../../utils/helpers.js";
import Loader from "../../components/common/Loader/Loader.jsx";
import Footer from "../../components/layout/Footer.jsx";
import mosqueVideo from "../../assets/videos/mosque-2.mp4";
import { selectMosque } from "../../features/mosque/mosqueSlice.ts";
import Dialog from "../../components/common/dialog/Dialog.tsx";
import { Language } from "../../enums/Language.ts";
import { openDialog } from "../../features/general/generalSlice.ts";
import walkingIcon from "../../assets/icons/walking.svg";
import walkingGreenIcon from "../../assets/icons/walking-green.svg";
import cyclingIcon from "../../assets/icons/cycling.svg";
import cyclingGreenIcon from "../../assets/icons/cycling-green.svg";
import drivingIcon from "../../assets/icons/driving.svg";
import drivingGreenIcon from "../../assets/icons/driving-green.svg";

const listModeTravel = [
  { name: "WALKING", image: walkingIcon, imageGreen: walkingGreenIcon },
  { name: "CYCLING", image: cyclingIcon, imageGreen: cyclingGreenIcon },
  { name: "DRIVING", image: drivingIcon, imageGreen: drivingGreenIcon },
];

const MosquePage = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const listMosques = useAppSelector((state) => state.mosque.listMosques);
  const nextPageToken = useAppSelector((state) => state.mosque.nextPageToken);
  const loading = useAppSelector((state) => state.mosque.loading);
  const [listMosquesFiltered, setListMosquesFiltered] = useState([]);
  const [position, setPosition] = useState({ lat: 0, lng: 0 });
  const [modeTransport, setModeTransport] = useState("DRIVING");
  // WALKING
  const selectedMosque = useAppSelector((state) => state.mosque.selectedMosque);
  const radius = useAppSelector((state) => state.mosque.radiusGettingMosque);
  const positionError = useAppSelector(
    (state) => state.localisationReducer.error
  );
  const dialog = useAppSelector((state) => state.generalSlice);

  useEffect(() => {
    getSetMyPosition();
  }, [radius, modeTransport]);

  useEffect(() => {
    if (positionError) {
      dispatch(
        openDialog({
          nameDialog: "errorLocalisation",
          title: "Autoriser l'accès à votre position !",
          description:
            "Muslim Community souhaite connaître votre position afin de partager des mises à jour pertinentes en temps réel",
        })
      );
    }
  }, [positionError]);

  const getSetMyPosition = () => {
    navigator.geolocation.getCurrentPosition(async (position) => {
      if (position) {
        setPosition({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        dispatch(
          fetchListMosques({ position: position.coords, radius, modeTransport })
        );
      }
    });
  };

  useEffect(() => {
    setListMosquesFiltered(listMosques);
  }, [listMosques]);

  const handleSearch = (event) => {
    if (event.target.value) {
      const serchedString = removeSpaces(
        removeDiacritics(event.target.value.toLowerCase())
      );
      const filteredMosques = listMosques.filter((el) =>
        removeSpaces(removeDiacritics(el.name.toLowerCase())).includes(
          serchedString
        )
      );
      setListMosquesFiltered(filteredMosques);
    } else {
      setListMosquesFiltered(listMosques);
    }
  };

  const getNextListMosque = () => {
    if (nextPageToken) {
      dispatch(
        fetchListMosques({
          position: position.coords,
          radius,
          nextPageToken,
          modeTransport,
        })
      );
    }
  };

  return loading === "loading" ? (
    <Loader />
  ) : dialog.dialogOpened && dialog.nameDialog === "errorLocalisation" ? (
    <Dialog secondaryButton={{ title: "OK" }}></Dialog>
  ) : (
    <div className="pages-position">
      <div className="header-mosque">
        <div className="">
          <video autoPlay muted loop id="video-mosque">
            <source src={mosqueVideo} type="video/mp4" />
            Your browser does not support HTML video.
          </video>
        </div>
      </div>
      <div className="mosque-body">
        <div className="list-mosques-container">
          <div className="mosques">
            <div>
              <input
                type="search"
                placeholder={t("mosque.FIND_MOSQUE")}
                onChange={handleSearch}
                style={{
                  backgroundPosition:
                    i18n.language === Language.AR ? "9px 96%" : "96% 9px",
                }}
              />
            </div>
            <div className="list-mosques">
              {listMosquesFiltered.map((mosque, index) => (
                <CardMosque
                  mosque={mosque}
                  key={index}
                  selected={
                    selectedMosque && selectedMosque.name === mosque.name
                  }
                ></CardMosque>
              ))}
              {nextPageToken && (
                <div className="flex justify-center">
                  <span className="cursor-pointer" onClick={getNextListMosque}>
                    Voir Plus
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mosque-map">
          <MyGoogleMap
            center={position}
            listPositions={listMosquesFiltered?.map(
              (mosque) => mosque.location
            )}
            itenerary={
              selectedMosque
                ? { from: position, to: selectedMosque.location }
                : null
            }
            myPosition={position}
          ></MyGoogleMap>
          <div className="mode-travel">
            {listModeTravel.map((mode) => (
              <img
                className="cursor-pointer"
                name={mode.name}
                src={modeTransport === mode.name ? mode.imageGreen : mode.image}
                alt=""
                onClick={() => setModeTransport(mode.name)}
              />
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const CardMosque = ({ mosque, selected }) => {
  const dispatch = useAppDispatch();
  const getitInerary = (mosque) => {
    dispatch(selectMosque(mosque));
  };

  return (
    <div
      className={"card-mosque " + (selected ? "selected-mosque" : "")}
      onClick={() => {
        getitInerary(mosque);
      }}
    >
      <img src={mosque.photo} alt="photo mosque" className="image-mosque" />
      <div>
        <span className="mosque-name">{mosque.name}</span>

        <div className="mosque-distance">
          <img
            src={selected ? positionIconWhite : positionIcon}
            alt="position"
            className="position-icon"
          />
          <span>{mosque.distance?.text}</span>
          <span>{mosque.duration?.text}</span>
        </div>
      </div>
    </div>
  );
};
export default MosquePage;
