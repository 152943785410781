import axios from "axios";
import { PRAYER_TIMES_API } from "../constants/apis.constants.ts";

export const getPrayerTimes = async (position, date) => {
  if (position) {
    const response = await axios.get(
      `${PRAYER_TIMES_API}/${date}?latitude=${position.latitude}&longitude=${position.longitude}&method=18`
    );
    if (response) {
      return response?.data?.data;
    }
  }
  return null;
};
