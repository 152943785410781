import "./CardSorah.scss";
import numSorah from "../../../assets/images/num-aya-transparent.svg";
import numSorahGreen from "../../../assets/images/num-aya-green.svg";
import makiyah from "../../../assets/logos/makiyyah.svg";
import medinanLogo from "../../../assets/logos/medinan.svg";
import iconBook from "../../../assets/images/icon-book.svg";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { selectSurahReducer } from "../../../features/quran/quranSlice.ts";
import { useAppDispatch } from "../../../app/hooks.ts";

const CardSorah = ({ data, selectedSorah, from }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const viewSurahDetails = () => {
    navigate("/quran/" + data.number.toString());
  };
  const [iconNumSorah, setIconNumSorah] = useState(
    !selectedSorah ? numSorahGreen : numSorah
  );

  useEffect(() => {
    if (selectedSorah) {
      dispatch(selectSurahReducer({ numSorah: data.number }));
      const goToTop = document.getElementsByClassName("selectedSorah");
      if (goToTop && goToTop[0]) {
        goToTop[0].scrollIntoView({ behavior: "smooth", block: "start" });
      }
    } else {
      setIconNumSorah(numSorahGreen);
    }
  }, [selectedSorah]);
  return (
    <div
      className={"container-surah font-semibold " + (from ? from : "")}
      onClick={viewSurahDetails}
      onMouseOver={(e) => {
        !selectedSorah && setIconNumSorah(numSorah);
      }}
      onMouseLeave={(e) => {
        !selectedSorah && setIconNumSorah(numSorahGreen);
      }}
    >
      <div
        className={
          "flex items-center justify-between " +
          (selectedSorah ? "selectedSorah " : "")
        }
        style={{ padding: "1em 1.5em", borderRadius: "8px" }}
      >
        <div className="flex gap-3">
          <div className="num-sorah">
            <img src={iconNumSorah} alt="num sorah" />
            <span>{data.number}</span>
          </div>
          <div className="surah-details">
            <span className="surah-name"> {data.englishName}</span>
            <div className="flex gap-2 mt-2 type-sorah-container">
              {from !== "surahPage" && (
                <div className="type-sorah">
                  <img
                    src={
                      data.revelationType === "Medinan" ? medinanLogo : makiyah
                    }
                    alt=""
                  />
                  {data.revelationType}
                </div>
              )}

              <div className="number-ayahs">
                <img src={iconBook} alt="book icon" />
                {data.numberOfAyahs + " Ayat"}
              </div>
            </div>
          </div>
        </div>
        <div className="arabic-name">
          <span className="text-xl md:text-2xl lg:text-3xl">{data.name}</span>
        </div>
      </div>
    </div>
  );
};

export default CardSorah;
