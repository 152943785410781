import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface MosqueState {
  dialogOpened: boolean;
  nameDialog: string;
  description: string;
  title: string;
  image: any;
}

const initialState: MosqueState = {
  dialogOpened: false,
  nameDialog: "",
  description: "",
  title: "",
  image: null,
};

const generalSlice = createSlice({
  name: "generalSlice",
  initialState,
  reducers: {
    openDialog(
      state,
      action: PayloadAction<{
        nameDialog: string;
        description: string;
        title: string;
        image?: any;
      }>
    ) {
      state.dialogOpened = true;
      state.nameDialog = action.payload?.nameDialog;
      state.description = action.payload?.description;
      state.title = action.payload?.title;
      state.image = action.payload?.image || null;
    },
    closeDialog(state) {
      state.dialogOpened = false;
      state.nameDialog = "";
    },
  },
});

export default generalSlice.reducer;
export const { openDialog, closeDialog } = generalSlice.actions;
