import imageOr from "../assets/images/zakat/or.svg";
import imageMoney from "../assets/images/zakat/money.svg";
import imageCamel from "../assets/images/zakat/camel.svg";
import imageCereals from "../assets/images/zakat/cereals.png";
import imageArgent from "../assets/images/zakat/argent.png";
import imageCattle from "../assets/images/zakat/cattle.png";
import imageSheep from "../assets/images/zakat/sheep.png";
import imageDate from "../assets/images/zakat/date.png";
import imageRaisin from "../assets/images/zakat/raisin.png";

export const PRAYER_LIST = [
  "Fajr",
  "Sunrise",
  "Dhuhr",
  "Asr",
  "Maghrib",
  "Isha",
];

export const LIST_SHEIKH = [
  {
    name: "Imam Ibn Hajar Al-Asqalani",
    description:
      "Il est né au Caire en 1372, fils du savant et poète Shafi'i Nur al-Din Ali. Ses deux parents étant morts pendant son enfance, il est devenu, ainsi que sa sœur, Sitt al-Rakb, pupille du frère de la première femme de son père, Zaki al-Din al-Kharrubi qui inscrit Ibn Hajar dans une école coranique alors qu'il n'avait que cinq ans.",
  },
  {
    name: "Imam An-Nawawi",
    description:
      "Al-Imam Muîn ad-Dîn Abu Zakariya Yahya ibn Sharaf ibn Marri ibn Hasan ibn Husayn ibn Hizam ibn Muhammad ibn Jumuah An-Nawawi est né en 631 de l'Hégire, soit en 1233 du calendrier julien, dans le village de Nawā dans la région de Ḥūrān au Sud de la Syrie.",
  },
  {
    name: "Sheikh Al Islam Ibn Taymiyya",
    description:
      "Taqî ad-Dîn Ahmad ibn Taymiyya, est un théologien et un juriconsulte musulman traditionaliste du XIIIᵉ siècle, influent au sein du madhhab hanbalite. Son époque est marquée par les conflits entre Mamelouks et Mongols, et il tente d'organiser le djihad contre ces derniers qu'il accuse de mécréance.",
  },
  {
    name: "Ibn Qayyim al-Jawziyya",
    description:
      "Abou ‘Abdi-Llâh Chams-oud-Dîn Mouhammad ibn Abî Bakr ibn Ayyub ibn Sa‘d ibn Hariz ibn Makki Zayn al-Din al-Zur‘i al-Dimashqi al-Hanbali, plus connu sous le nom de Ibn Qayyim al-Djawziyyah, parfois abrégé Ibn Qayyim (de manière plus correcte Ibn al-Qayyim), né le 7 Safar 691 AH/29 janvier 1292 à Damas (sultanat mamelouk d'Égypte) et mort le 15 septembre 1350 dans cette même ville, est un célèbre imam, savant (ʿālim), juriste (faqīh), mufti et poète arabe de religion musulmane sunnite, apparenté au madhhab hanbalite.",
  },
  {
    name: "Ibn Kathir",
    description:
      "Ibn Kathīr de son nom complet Abu al-Fiḍā ‘Imād Ad-Din Ismā‘īl ibn ‘Umar ibn Kathīr al-Qurashī Al-Damishqī est un juriste shâfi'ite, traditionniste et historien. Il est né en 701 AH / 1301 apr. J.-C. à Bosra et mort le 26 cha'ban 774 AH / 1er février 1373 apr. J.-C. à Damas.",
  },
  {
    name: "Sheikh Muhammad Ibn AbdulWahab",
    description:
      "Mohammed ben Abdelwahhab dit Ibn Abdelwahhab est un théologien du Nejd, dans le centre de l'Arabie, qui a prêché une vision de l'islam réformatrice.",
  },
  {
    name: "Sheikh Al Albani",
    description:
      "Muhammad Nasir-ud-Din al-Albani, né en 1914 à Shkodër et mort apatride le 2 octobre 1999 à Amman, est un théologien, juriste et savant de l'islam, spécialisé dans la jurisprudence islamique et dans l'authentification des hadiths.",
  },
  {
    name: "Sheikh Muhammed Al Uthaymin",
    description:
      "Ash-Shaykh abou AbdAllah Mouhammad ibnou Saalih al-'Uthaymin, nom complet Ash-Shaykh abou AbdAllah Mouhammad ibnou Saalih ibn Mouhammad ibnou al-'Uthaymin at-Tamimi (1925-2001) est un 'ʿālim savant saoudien, il fut l'une des personnalités religieuses saoudiennes les plus respectées en Arabie saoudite.",
  },
  {
    name: "Sheikh AbderRahman As-Sa'di",
    description:
      "Abd ar-Rahman ibn Nasir as-Sadi at-Tamimi est un savant musulman, juriste, exégète, grammairien et poète arabe. Il composa de nombreux ouvrages à propos de l'islam.",
  },
  {
    name: "Sheikh Muqbil Al Wadi'i",
    description:
      "Muqbil ibn Hadi al-Wadi'i est un savant renommé dans le monde musulman et un des promoteurs de l'islam selon le Coran et la sunnah du prophète Muhammed.",
  },
  {
    name: "Sheikh Abdel-Aziz Ibn Baz",
    description:
      "Abd al-Aziz ibn Abd Allah ibn Baz (en arabe : عبد العزيز بن عبد الله بن باز), connu sous le nom d'Ibn Baz, né le 21 novembre 1910 et mort le 13 mai 1999 , est un religieux, de nationalité saoudienne. Il a été le grand mufti et président du Conseil des grands ouléma de l'Arabie saoudite de 1993 jusqu'à sa mort.",
  },
  {
    name: "Sheikh Khalil Harras",
    description:
      "Mohammed al-Amîne ash-Shanqîtî1 (17 février 1907 - 10 janvier 1973) était un érudit musulman d'origine mauritanienne. Son prénom Mohammed al-Amîne est un prénom composé tout comme son père (Mohammed al-Mukhtâr) qui est une manière courante de se nommer dans leur pays.",
  },
  {
    name: "Sheikh Muhammad Al Wusabi",
    description:
      "Sheikh Muhammed Al Wusabi est un érudit émirati renommé dans le domaine des études islamiques. Né en 1956, il a écrit de nombreux livres et articles sur l'islam et enseigné à l'Université islamique de Médine. Son travail est largement acclamé et respecté par les érudits et les étudiants.",
  },
  {
    name: "Sheikh Muhammed Ali Ferkous",
    description:
      "Sheikh Muhammad Ali Ferkous: American Sheik, director of the Institute of Applied Quranic Studies, and founder of humanitarian and educational organizations promoting Islamic values. Born in Somalia, he is a respected spiritual leader advocating for peace and social justice internationally.",
  },
  {
    name: "Sheikh Saleh Al Fawzan",
    description:
      "Sheikh Saleh Al Fawzan est un érudit, chercheur et écrivain saoudien renommé. Professeur à l'Université islamique de Médine, il a écrit de nombreux livres et articles savants sur l'islam, la grammaire arabe et l'histoire. Il est largement respecté pour sa connaissance approfondie et son expertise dans ces domaines, et a été reconnu pour son travail de promotion de la culture et de l'éducation islamiques à l'échelle mondiale.",
  },
  {
    name: "Sheikh Salih As-Souhaymi",
    description:
      "Sheikh Salih As-Souhaymi, savant, orateur et écrivain islamique, était connu pour sa polyvalence dans divers domaines tels que les études islamiques, la littérature arabe et la poésie. Il a attiré de larges audiences grâce à ses nombreux sermons religieux et était également un auteur prolifique. Respecté jusqu'à son décès en 2012.",
  },
  {
    name: "Sheikh Muhammad Sa'id Raslan",
    description:
      "Sheikh Muhammad Sa'id Raslan était un érudit et avocat islamique syrien renommé. Né en 1918, il est décédé en 1995. Raslan était reconnu pour son expertise en droit et philosophie islamiques, et a écrit plusieurs livres sur ces sujets. Il a également été juge et une figure éminente de la communauté intellectuelle islamique. Ses contributions ont eu un impact durable sur la pensée islamique et sont toujours étudiées et respectées par les érudits aujourd'hui.",
  },
  {
    name: "Sheikh Abdullah Al-Bukhari",
    description:
      "Sheikh Abdullah bin Muhammad bin Al-Sa'd bin Abdul Malik Al-Bukhari était un érudit islamique renommé, né en 1766 au Cachemire, en Inde, et décédé en 1840 à La Mecque, en Arabie saoudite. Il était un expert dans la collection des hadiths et la jurisprudence islamique, et a écrit plusieurs livres précieux dans ce domaine.",
  },
  {
    name: "Sheikh Abdul Muhsin Al Abbad",
    description:
      "Sheikh AbdelMuhsin Al Abbad est un érudit religieux saoudien et le Grand Mufti d'Arabie saoudite. Né en 1935, il est reconnu pour son expertise en droit islamique et son rôle dans la formulation des politiques religieuses du pays. Ses positions sur diverses questions, dont les droits des femmes et la tolérance religieuse, ont attiré une attention mondiale.",
  },
  {
    name: "Sheikh AbderRazzaq Al Abbad",
    description:
      "Sheikh AbderRazzaq Al Abbad, érudit et écrivain saoudien, né en 1957, est reconnu pour son expertise dans les études islamiques et la littérature arabe. Il a écrit sur la finance islamique, l'économie et les problématiques sociales. Ses écrits et enseignements sont reconnus à l'international pour leur pertinence actuelle.",
  },
  {
    name: "Sheikh Muhammed Aman Al Jami",
    description:
      "Sheikh Muhammed Aman Al-Jami, érudit islamique ouïghour renommé du Xinjiang, Chine. Né en 1428, ses travaux marquent la théologie islamique, le soufisme et la fusion de pensées islamiques et chinoises. Ses écrits majeurs sont la Divine Comédie et l'Encyclopédie de l'Ordre dominicain, bénéfiques pour la pensée islamique, le soufisme et le dialogue interreligieux.",
  },
  {
    name: "Sheikh Abdul-Qadir Al Arna'ut",
    description:
      "Sheikh Abdul-Qadir Al Arna'ut est un éminent érudit islamique, spécialisé dans les hadith, le tafsir et le fiqh. Il a écrit de nombreux livres et articles respectés pour leur érudition et clarté. En tant que président du Centre de recherche islamique et professeur à l'Université islamique de l'Imam Muhammad bin Saud, il est une figure marquante dans le domaine des études islamiques.",
  },
  {
    name: "Sheikh Abdullah Al Ghoudayan",
    description:
      "Sheikh Abdullah Al Ghoudayan était un éminent érudit islamique saoudien et un fervent défenseur de la forme salafiste de l'islam. Il était connu pour ses contributions savantes et son influence au sein du mouvement salafiste.",
  },
  {
    name: "Cheikh Ahmad An-Najmi",
    description:
      "Cheikh ‘Abd Allâh ibn Mouhammad Hussein An-Najmy, né en 1391 H (1971) à An-Najamiyah, Jizan, est diplômé de l’université islamique Mouhammad Ibn Saoud en Traditions Prophétiques. Il a enseigné les matières islamiques pendant 20 ans et continue à diriger des cours et des conférences dans sa région. Parmi ses enseignants figurent des érudits renommés tels que Cheikh Ahmad An-Najmy, Cheikh Zayd Al-Madkhali, et Cheikh Abdoul ’Aziz Ibn Bâz.",
  },
  {
    name: "Sheikh Muhammad Al Aqil",
    description:
      "Sheikh Muhammed Al-Aqil, célèbre érudit saoudien en études islamiques et langue arabe, est reconnu pour ses écrits sur la théologie islamique, l'exégèse coranique et les Hadiths. Il œuvre pour la promotion de la spiritualité islamique et la connexion entre traditions islamiques et société moderne, influençant des chercheurs mondialement.",
  },
  {
    name: "Sheikh Hassan Ibn AbdulWahhab Al Banna",
    description:
      "Hassan el-Benna ou al-Bannâ (en arabe حسن البنا), né le 14 octobre 1906 à Mahmoudiyah et mort assassiné le 12 février 1949 au Caire1, est un instituteur égyptien, fondateur des Frères musulmans1. Il est le grand-père de Tariq Ramadan et Hani Ramadan.",
  },
  {
    name: "Sheikh Hammad Al Ansari",
    description:
      "Hamad Al-Ansari (arabe : 1925-1997) était un érudit musulman du 20e siècle qui était membre du corps enseignant de l'Université islamique de Médine.[1][2] Al-Ansari s'est spécialisé dans la science islamique du hadith, mais ses contributions académiques ont couvert toutes les sciences islamiques, et il a eu une influence sur de nombreux érudits et personnalités islamiques éminents actuels.",
  },
  {
    name: "Sheikh Muhammad Al Madkhali",
    description:
      "Cheikh Muhammad Al Madkhali est un professeur saoudien qui était l'ancien chef du département d'études sur la Sunna à l'Université islamique de Médine. Il est connu pour ses opinions conservatrices et a publié de nombreux articles sur la théologie et la jurisprudence islamiques. Certaines de ses œuvres, comme Les Fondements de la Sunnah, sont considérées comme influentes dans le monde musulman.",
  },
  {
    name: "Sheikh Rabi' Al Madkhali",
    description:
      "Rabee al-Madkhali est un professeur saoudien qui était l'ancien chef du département d'études sur la Sunna à l'Université islamique de Médine. C'est un érudit musulman salafiste connu pour ses opinions conservatrices et controversées. Des informations supplémentaires sur Cheikh Rabi' Al Madkhali peuvent être trouvées dans l'article Wikipédia en français, ainsi que dans des vidéos et des publications présentant ses enseignements et ses fatwas.",
  },
  {
    name: "Sheikh Zayd Al Madkhali",
    description:
      "Shaykh Zayd ibn Muhammad al-Madkhali était un illustre érudit islamique et expert en jurisprudence. Il est né en 1357H à Rukoobah, en Arabie Saoudite et était connu comme un vestige de ses pieux prédécesseurs. Zayd al-Madkhali est l'auteur de plusieurs livres sur la croyance islamique et la Sunna, notamment Les Fondements de la Sunnah et Charh As-Sunna. Il a également donné des conférences sur des sujets tels que Laylat al-Qadr et la récitation du dua Taraweeh.",
  },
  {
    name: "Sheikh Muhammad Bazmoul",
    description:
      "Shaykh Muhammad ibn 'Umar ibn Saalim Baazmool est un professeur respecté à l'Université Umm al-Quraa en Arabie Saoudite, renommé pour ses compétences en études islamiques supérieures. Il a également rédigé un travail remarquable expliquant une célèbre risala de Shaykh Muhammad ibn 'Abdil-Wahhab. Shaykh Ahmad Bazmool, un autre érudit bien connu de La Mecque, enseigne la Science du Hadith à l'Université Umm al-Quraa.",
  },
  {
    name: "Sheikh Ahmed Bazmoul",
    description:
      "Cheikh Ahmed Bazmoul est un prédicateur et conférencier saoudien de renom dans le monde musulman. Né en 1975 à Djeddah, en Arabie saoudite, il est l'auteur de plusieurs livres sur des thèmes islamiques tels que la Sunnah et la médecine. Il a également donné des conférences audio, comme en témoigne le contenu audio biographique. De plus, ses œuvres sont disponibles sur diverses plateformes en ligne, destinées à la communauté musulmane",
  },
];

export const LIST_RECITERS = [
  { label: "Alafasy", label_ar: "العفاسي", value: "ar.alafasy" },
  {
    label: "Abd Elbaset Abd Samad",
    label_ar: "عبد الباسط عبد الصمد",
    value: "ar.abdulbasitmujawwad",
  },
  {
    label: "Ali Abd Elrahman Alhudhayfi",
    label_ar: "علي عبد الرحمن الحذيفي",
    value: "ar.aliabdurrahmanalhuthaify",
  },
  {
    label: "Abdullah Basfar",
    label_ar: "عبد الله بصفر",
    value: "ar.abdullahbasfar",
  },
];

export const GHOUSL_STEPS = [
  {
    title: "learn.WUDHU_GHOUSL.GHOUSL_PAGE.STEP_1_TITLE",
    description: "learn.WUDHU_GHOUSL.GHOUSL_PAGE.STEP_1_DESCRIPTION",
  },
  {
    title: "learn.WUDHU_GHOUSL.GHOUSL_PAGE.STEP_2_TITLE",
    description: "learn.WUDHU_GHOUSL.GHOUSL_PAGE.STEP_2_DESCRIPTION",
  },
  {
    title: "learn.WUDHU_GHOUSL.GHOUSL_PAGE.STEP_3_TITLE",
    description: "learn.WUDHU_GHOUSL.GHOUSL_PAGE.STEP_3_DESCRIPTION",
  },
  {
    title: "learn.WUDHU_GHOUSL.GHOUSL_PAGE.STEP_4_TITLE",
    description: "learn.WUDHU_GHOUSL.GHOUSL_PAGE.STEP_4_DESCRIPTION",
  },
  {
    title: "learn.WUDHU_GHOUSL.GHOUSL_PAGE.STEP_5_TITLE",
    description: "learn.WUDHU_GHOUSL.GHOUSL_PAGE.STEP_5_DESCRIPTION",
  },
  {
    title: "learn.WUDHU_GHOUSL.GHOUSL_PAGE.STEP_6_TITLE",
    description: "learn.WUDHU_GHOUSL.GHOUSL_PAGE.STEP_6_DESCRIPTION",
  },
  {
    title: "learn.WUDHU_GHOUSL.GHOUSL_PAGE.STEP_7_TITLE",
    description: "learn.WUDHU_GHOUSL.GHOUSL_PAGE.STEP_7_DESCRIPTION",
  },
  {
    title: "learn.WUDHU_GHOUSL.GHOUSL_PAGE.STEP_8_TITLE",
    description: "learn.WUDHU_GHOUSL.GHOUSL_PAGE.STEP_8_DESCRIPTION",
  },
  {
    title: "learn.WUDHU_GHOUSL.GHOUSL_PAGE.STEP_9_TITLE",
    description: "learn.WUDHU_GHOUSL.GHOUSL_PAGE.STEP_9_DESCRIPTION",
  },
];

export const LIST_POSSESSIONS_ZAKAT = [
  {
    value: "or_argent",
    name: "zakat.OR_ARGENT",
    description: "zakat.OR_ARGENT_DESCRIPTION",
    image: imageOr,
    unit: "g",
    list: [
      {
        value: "or",
        name: "zakat.OR",
        image: imageOr,
        description: "zakat.OR_DESCRIPTION",
        amount: 0,
        nisab: 85,
      },
      {
        value: "argent",
        name: "zakat.ARGENT",
        image: imageArgent,
        description: "zakat.ARGENT_DESCRIPTION",
        amount: 0,
        nisab: 595,
      },
    ],
  },
  {
    value: "cache",
    name: "zakat.MONEY",
    description: "zakat.MONEY_DESCRIPTION",
    image: imageMoney,
    unit: "TND",
    list: [
      {
        value: "encaisse",
        name: "zakat.CASH",
        description: "zakat.MONEY_DESCRIPTION_2",
        amount: 0,
      },
      {
        value: "especes_bancaire",
        name: "zakat.CASH_BANK",
        description: "zakat.MONEY_DESCRIPTION_2",
        amount: 0,
      },
    ],
  },
  {
    value: "livestock",
    name: "zakat.LIVESTOCK",
    description: "zakat.LIVESTOCK_DESCRIPTION",
    image: imageCamel,
    unit: "Qte",
    list: [
      {
        value: "cattle",
        name: "zakat.CATTLE",
        image: imageCattle,
        description: "zakat.CATTLE_DESCRIPTION",
        amount: 0,
        nisab: 30,
      },
      {
        value: "camel",
        name: "zakat.CAMEL",
        image: imageCamel,
        description: "zakat.CAMEL_DESCRIPTION",
        amount: 0,
        nisab: 5,
      },
      {
        value: "sheep",
        name: "zakat.SHEEP",
        image: imageSheep,
        description: "zakat.SHEEP_DESCRIPTION",
        amount: 0,
        nisab: 40,
      },
    ],
  },
  {
    value: "agriculture",
    name: "zakat.AGRICULTURE",
    description: "zakat.AGRICULTURE_DESCRIPTION",
    image: imageCereals,
    unit: "Kg",
    list: [
      {
        value: "date",
        name: "zakat.DATE",
        description: "zakat.AGRICULTURE_DESCRIPTION_2",
        image: imageDate,
        amount: 0,
        nisab: 612,
        type: "irrigated",
      },
      {
        value: "raisin",
        name: "zakat.RAISIN",
        description: "zakat.AGRICULTURE_DESCRIPTION_2",
        image: imageRaisin,
        amount: 0,
        nisab: 612,
        type: "irrigated",
      },
      {
        value: "cereals",
        name: "zakat.CEREALS",
        description: "zakat.AGRICULTURE_DESCRIPTION_2",
        image: imageCereals,
        amount: 0,
        nisab: 612,
        type: "irrigated",
      },
    ],
  },
];
