import { useTranslation } from "react-i18next";
import "./ProphetsStoriesPage.scss";
import { useEffect } from "react";

import prophetsVideo from "../../assets/videos/StoryBlock.mp4";
import adamImage from "../../assets/images/stories/adam.svg";
import idrisImage from "../../assets/images/stories/idris.svg";
import nuhImage from "../../assets/images/stories/nuh.svg";
import hudImage from "../../assets/images/stories/hud.svg";
import salihImage from "../../assets/images/stories/salih.svg";
import ibrahimImage from "../../assets/images/stories/ibrahim.svg";
import ismailImage from "../../assets/images/stories/ismail.svg";
import lutImage from "../../assets/images/stories/lut.svg";
import ishakImage from "../../assets/images/stories/ishak.svg";
import yakubImage from "../../assets/images/stories/yakub.svg";
import yusufImage from "../../assets/images/stories/yusuf.svg";
import ayubImage from "../../assets/images/stories/ayub.svg";
import shuaybImage from "../../assets/images/stories/shuayb.svg";
import musaImage from "../../assets/images/stories/musa.svg";
import harounImage from "../../assets/images/stories/haroun.svg";
import dhulkiflImage from "../../assets/images/stories/dhulkifl.svg";
import dawudImage from "../../assets/images/stories/dawud.svg";
import sulaimanImage from "../../assets/images/stories/sulaiman.svg";
import ilyasImage from "../../assets/images/stories/ilyas.svg";
import alyasaImage from "../../assets/images/stories/alyasa.svg";
import yunusImage from "../../assets/images/stories/yunus.svg";
import zakaryaImage from "../../assets/images/stories/zakarya.svg";
import yahyaImage from "../../assets/images/stories/yahya.svg";
import isaImage from "../../assets/images/stories/isa.svg";
import muhammedImage from "../../assets/images/stories/muhammed.svg";
import Footer from "../../components/layout/Footer";
import { useNavigate } from "react-router-dom";

const ProphetsStoriesPage = () => {
  const { t } = useTranslation();
  const listProphetes = [
    {
      name: "ADAM",
      image: adamImage,
      description:
        "Recognized as the first human and prophet. His creation marks the beginning of the human race",
    },
    {
      name: "IDRIS",
      image: idrisImage,
      description:
        "He was a man of truth and patience and was raised to a high station by Allah.",
    },
    {
      name: "NUH",
      image: nuhImage,
      description:
        "He was sent to guide a people who had deviated. He built an ark to save the believers from a great flood.",
    },
    {
      name: "HUD",
      image: hudImage,
      description:
        "Sent to the people of ‘Ad, who were known for their mighty power but rejected the signs of God.",
    },
    {
      name: "SALIH",
      image: salihImage,
      description:
        "Sent to the people of Thamud who demanded a sign and were shown the miracle of the she-camel.",
    },
    {
      name: "IBRAHIM",
      image: ibrahimImage,
      description:
        "Recognized for his strong faith, he rejected his people’s idolatry and was tested many times by Allah, including being commanded to sacrifice his son.",
    },
    {
      name: "ISMAIL",
      image: ismailImage,
      description:
        "He was known for his patience and obedience when his father Ibrahim was commanded to sacrifice him.",
    },
    {
      name: "LUT",
      image: lutImage,
      description:
        "Sent to a people who had lost their moral compass, indulging in homosexuality",
    },
    {
      name: "ISHAK",
      image: ishakImage,
      description:
        "The son of Ibrahim and the father of Yaqub. He was blessed with prophethood like his forefathers.",
    },
    {
      name: "YAKUB",
      image: yakubImage,
      description:
        "He was gifted with a deep understanding, and his story is closely linked with his son, Yusuf.",
    },
    {
      name: "YUSUF",
      image: yusufImage,
      description:
        "He was known for his patience and steadfastness. His life story, involving betrayal by his brothers, his time in Egypt, and his eventual reunion with his family, is detailed in Surah Yusuf.",
    },
    {
      name: "AYUB",
      image: ayubImage,
      description: "",
    },
    {
      name: "SHUAYB",
      image: shuaybImage,
      description:
        "Sent to the people of Madyan who were engaged in fraudulent business practices.",
    },
    {
      name: "MUSA",
      image: musaImage,
      description:
        "Sent to Pharaoh and the Children of Israel. His life includes many notable events, such as the parting of the sea.",
    },
    {
      name: "HAROUN",
      image: harounImage,
      description:
        "Brother and assistant of Musa, he played a crucial role during the exodus.",
    },
    {
      name: "DHUL-KIFL",
      image: dhulkiflImage,
      description:
        "Little is known about him, but he is believed to have been a prophet.",
    },
    {
      name: "DAWUD",
      image: dawudImage,
      description:
        "A king and prophet known for his wisdom, justice, and melodious voice.",
    },
    {
      name: "SULAIMAN",
      image: sulaimanImage,
      description:
        "Dawud’s son, known for his wisdom, was able to communicate with animals and jinn.",
    },
    {
      name: "ILYAS",
      image: ilyasImage,
      description: "Sent to the people who worshipped the idol Baal.",
    },
    {
      name: "ALYASA",
      image: alyasaImage,
      description: "Successor of Ilyas and known for his miracles.",
    },
    {
      name: "YUNUS",
      image: yunusImage,
      description:
        "He is known for being swallowed by a giant fish after he abandoned his people without God’s permission.",
    },
    {
      name: "ZAKARYA",
      image: zakaryaImage,
      description: "He was blessed with a son, Yahya, in old age.",
    },
    {
      name: "YAHYA",
      image: yahyaImage,
      description: "Known for his piety and chastity.",
    },
    {
      name: "ISA",
      image: isaImage,
      description:
        "Born miraculously to Maryam (Mary) and known for his numerous miracles.",
    },
    {
      name: "MUHAMMED",
      image: muhammedImage,
      description:
        "The final prophet and messenger sent to humanity with the complete and final revelation, the Quran.",
    },
  ];

  useEffect(() => {
    document.querySelector("#video-prophets").playbackRate = 0.6;
    const goToTop = document.getElementsByClassName("prophets-header");
    if (goToTop && goToTop[0]) {
      goToTop[0].scrollIntoView({ block: "start", behavior: "instant" });
    }

    const heightStory =
      window.innerWidth > 1300
        ? 300
        : window.innerWidth > 800
        ? 260
        : window.innerWidth > 500
        ? 240
        : 220;

    let firstInvisibleStory = Math.trunc(window.innerHeight / heightStory);
    let nextStory = firstInvisibleStory;

    setTimeout(() => {
      for (let i = 0; i < nextStory; i++) {
        const element = document.getElementById(listProphetes[i].name);
        element.classList.add("visible-story");
      }
      const hrVertical = document.querySelector("hr.vertical");
      hrVertical.classList.remove("hidden-hr");
    }, 100);

    window.addEventListener("scroll", handleScrolling);
    let lastScrollTop = 0;
    let typeScroll = "";
    function handleScrolling(event) {
      const scrollPosition = window.scrollY;

      if (window.scrollY > lastScrollTop) {
        typeScroll = "down";
      } else if (window.scrollY < lastScrollTop) {
        typeScroll = "up";
      }
      lastScrollTop = scrollPosition <= 0 ? 0 : scrollPosition;

      if (
        typeScroll === "down" &&
        nextStory < listProphetes.length &&
        scrollPosition > heightStory * (nextStory + 1 - firstInvisibleStory)
      ) {
        const element = document.getElementById(listProphetes[nextStory].name);

        element.classList.add("visible-story");
        nextStory++;
      } else {
        if (
          typeScroll === "up" &&
          listProphetes[nextStory] &&
          scrollPosition < heightStory * (nextStory + 1 - firstInvisibleStory)
        ) {
          const element = document.getElementById(
            listProphetes[nextStory].name
          );
          element.classList.remove("visible-story");
          nextStory--;
        } else if (nextStory === listProphetes.length) {
          nextStory--;
        }
      }
    }
    return () => {
      window.removeEventListener("scroll", handleScrolling);
    };
  }, []);

  return (
    <div className="prophets-page">
      <div className="pages-position">
        <div className="prophets-header">
          <div className="">
            <video autoPlay muted loop id="video-prophets">
              <source src={prophetsVideo} type="video/mp4" />
              Your browser does not support HTML video.
            </video>
          </div>
          {/* <span className="title-page">{t("links.PROPHETS_STORIES")}</span> */}
        </div>
        <div className="prophets-body">
          <div className="stories-container">
            <hr className="vertical hidden-hr" />

            {listProphetes.map((prophete) => (
              <ProphetStory prophete={prophete} key={prophete.name} />
            ))}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

const ProphetStory = ({ prophete }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="prophet-story hidden-story" id={prophete.name}>
      <hr className="horizental" />
      <div className="prophet-circle"></div>
      <h1>{t("prophet_story." + prophete.name + ".NAME")}</h1>
      <p>{t("prophet_story." + prophete.name + ".DESCRIPTION")}</p>
      <img
        src={prophete.image}
        alt="story image"
        onClick={() => navigate(prophete.name)}
      />
    </div>
  );
};

export default ProphetsStoriesPage;
