import "./Error404.scss";
import video404 from "../../assets/videos/404.mp4";
const Error404 = () => {
  return (
    <div className="error-not-found">
      <div className="content">
        <video autoPlay muted loop id="video-home">
          <source src={video404} type="video/mp4" />
          Your browser does not support HTML video.
        </video>
      </div>
    </div>
  );
};
export default Error404;
