import step1S from "../assets/images/salat/1.png";
import step2S from "../assets/images/salat/2.png";
import step3S from "../assets/images/salat/3.png";
// import step4S from "../assets/images/salat/4.png";
import step5S from "../assets/images/salat/5.png";
import step6S from "../assets/images/salat/6.png";
import step8S from "../assets/images/salat/8.png";
import step10S from "../assets/images/salat/10.png";
import step11S from "../assets/images/salat/11.png";
// import step12S from "../assets/images/salat/12.png";
import step13S from "../assets/images/salat/13.png";

import fajrImage from "../assets/logos/prayer/fajr.png";
import dhuhrImage from "../assets/logos/prayer/dhuhr.png";
import asrImage from "../assets/logos/prayer/asr.png";
import maghribImage from "../assets/logos/prayer/maghrib.png";
import ishaImage from "../assets/logos/prayer/isha.png";

export const PRAYERS_LIST = [
  {
    name: "Fajr",
    title: "prayer.Sobh",
    image: fajrImage,
    description: "learn.SALAT.fajr.DESCRIPTION",
    steps: [
      { title: "STEP_1_TITLE_2", index: 0 },
      { title: "STEP_2_TITLE_2", index: 1 },
      { title: "STEP_3_TITLE_2", index: 2 },
      { title: "STEP_4_TITLE_2", index: 3 },
      { title: "STEP_5_TITLE_2", index: 4 },
      { title: "STEP_6_TITLE_2", index: 5 },
      { title: "STEP_7_TITLE_2", index: 6 },
      { title: "STEP_8_TITLE_2", index: 7 },

      { title: "STEP_2_TITLE_2", index: 1 },
      { title: "STEP_3_TITLE_2", index: 2 },
      { title: "STEP_4_TITLE_2", index: 3 },
      { title: "STEP_5_TITLE_2", index: 4 },
      { title: "STEP_6_TITLE_2", index: 5 },
      { title: "STEP_7_TITLE_2", index: 6 },

      { title: "STEP_9_TITLE_2", index: 8 },
      { title: "STEP_10_TITLE_2", index: 9 },
      { title: "STEP_11_TITLE_2", index: 10 },
    ],
  },
  {
    name: "Dhuhr",
    title: "prayer.Dhuhr",
    image: dhuhrImage,
    description: "learn.SALAT.dhuhr.DESCRIPTION",
    steps: [
      { title: "STEP_1_TITLE_2", index: 0 },
      { title: "STEP_2_TITLE_2", index: 1 },
      { title: "STEP_3_TITLE_2", index: 2 },
      { title: "STEP_4_TITLE_2", index: 3 },
      { title: "STEP_5_TITLE_2", index: 4 },
      { title: "STEP_6_TITLE_2", index: 5 },
      { title: "STEP_7_TITLE_2", index: 6 },
      { title: "STEP_8_TITLE_2", index: 7 },

      { title: "STEP_2_TITLE_2", index: 1 },
      { title: "STEP_3_TITLE_2", index: 2 },
      { title: "STEP_4_TITLE_2", index: 3 },
      { title: "STEP_5_TITLE_2", index: 4 },
      { title: "STEP_6_TITLE_2", index: 5 },
      { title: "STEP_7_TITLE_2", index: 6 },

      { title: "STEP_9_TITLE_2", index: 8 },
      { title: "STEP_8_TITLE_2", index: 7 },

      { title: "STEP_2_TITLE_2", index: 1 },
      { title: "STEP_3_TITLE_2", index: 2 },
      { title: "STEP_4_TITLE_2", index: 3 },
      { title: "STEP_5_TITLE_2", index: 4 },
      { title: "STEP_6_TITLE_2", index: 5 },
      { title: "STEP_7_TITLE_2", index: 6 },
      { title: "STEP_8_TITLE_2", index: 7 },

      { title: "STEP_2_TITLE_2", index: 1 },
      { title: "STEP_3_TITLE_2", index: 2 },
      { title: "STEP_4_TITLE_2", index: 3 },
      { title: "STEP_5_TITLE_2", index: 4 },
      { title: "STEP_6_TITLE_2", index: 5 },
      { title: "STEP_7_TITLE_2", index: 6 },

      { title: "STEP_9_TITLE_2", index: 8 },

      { title: "STEP_10_TITLE_2", index: 9 },
      { title: "STEP_11_TITLE_2", index: 10 },
    ],
  },
  {
    name: "Asr",
    title: "prayer.Asr",
    image: asrImage,
    description: "learn.SALAT.asr.DESCRIPTION",
    steps: [
      { title: "STEP_1_TITLE_2", index: 0 },
      { title: "STEP_2_TITLE_2", index: 1 },
      { title: "STEP_3_TITLE_2", index: 2 },
      { title: "STEP_4_TITLE_2", index: 3 },
      { title: "STEP_5_TITLE_2", index: 4 },
      { title: "STEP_6_TITLE_2", index: 5 },
      { title: "STEP_7_TITLE_2", index: 6 },
      { title: "STEP_8_TITLE_2", index: 7 },

      { title: "STEP_2_TITLE_2", index: 1 },
      { title: "STEP_3_TITLE_2", index: 2 },
      { title: "STEP_4_TITLE_2", index: 3 },
      { title: "STEP_5_TITLE_2", index: 4 },
      { title: "STEP_6_TITLE_2", index: 5 },
      { title: "STEP_7_TITLE_2", index: 6 },

      { title: "STEP_9_TITLE_2", index: 8 },
      { title: "STEP_8_TITLE_2", index: 7 },

      { title: "STEP_2_TITLE_2", index: 1 },
      { title: "STEP_3_TITLE_2", index: 2 },
      { title: "STEP_4_TITLE_2", index: 3 },
      { title: "STEP_5_TITLE_2", index: 4 },
      { title: "STEP_6_TITLE_2", index: 5 },
      { title: "STEP_7_TITLE_2", index: 6 },
      { title: "STEP_8_TITLE_2", index: 7 },

      { title: "STEP_2_TITLE_2", index: 1 },
      { title: "STEP_3_TITLE_2", index: 2 },
      { title: "STEP_4_TITLE_2", index: 3 },
      { title: "STEP_5_TITLE_2", index: 4 },
      { title: "STEP_6_TITLE_2", index: 5 },
      { title: "STEP_7_TITLE_2", index: 6 },

      { title: "STEP_9_TITLE_2", index: 8 },

      { title: "STEP_10_TITLE_2", index: 9 },
      { title: "STEP_11_TITLE_2", index: 10 },
    ],
  },
  {
    name: "Maghrib",
    title: "prayer.Maghrib",
    image: maghribImage,
    description: "learn.SALAT.maghrib.DESCRIPTION",
    steps: [
      { title: "STEP_1_TITLE_2", index: 0 },
      { title: "STEP_2_TITLE_2", index: 1 },
      { title: "STEP_3_TITLE_2", index: 2 },
      { title: "STEP_4_TITLE_2", index: 3 },
      { title: "STEP_5_TITLE_2", index: 4 },
      { title: "STEP_6_TITLE_2", index: 5 },
      { title: "STEP_7_TITLE_2", index: 6 },
      { title: "STEP_8_TITLE_2", index: 7 },

      { title: "STEP_2_TITLE_2", index: 1 },
      { title: "STEP_3_TITLE_2", index: 2 },
      { title: "STEP_4_TITLE_2", index: 3 },
      { title: "STEP_5_TITLE_2", index: 4 },
      { title: "STEP_6_TITLE_2", index: 5 },
      { title: "STEP_7_TITLE_2", index: 6 },

      { title: "STEP_9_TITLE_2", index: 8 },
      { title: "STEP_8_TITLE_2", index: 7 },

      { title: "STEP_2_TITLE_2", index: 1 },
      { title: "STEP_3_TITLE_2", index: 2 },
      { title: "STEP_4_TITLE_2", index: 3 },
      { title: "STEP_5_TITLE_2", index: 4 },
      { title: "STEP_6_TITLE_2", index: 5 },
      { title: "STEP_7_TITLE_2", index: 6 },

      { title: "STEP_9_TITLE_2", index: 8 },

      { title: "STEP_10_TITLE_2", index: 9 },
      { title: "STEP_11_TITLE_2", index: 10 },
    ],
  },
  {
    name: "Isha",
    title: "prayer.Isha",
    image: ishaImage,
    description: "learn.SALAT.isha.DESCRIPTION",
    steps: [
      { title: "STEP_1_TITLE_2", index: 0 },
      { title: "STEP_2_TITLE_2", index: 1 },
      { title: "STEP_3_TITLE_2", index: 2 },
      { title: "STEP_4_TITLE_2", index: 3 },
      { title: "STEP_5_TITLE_2", index: 4 },
      { title: "STEP_6_TITLE_2", index: 5 },
      { title: "STEP_7_TITLE_2", index: 6 },
      { title: "STEP_8_TITLE_2", index: 7 },

      { title: "STEP_2_TITLE_2", index: 1 },
      { title: "STEP_3_TITLE_2", index: 2 },
      { title: "STEP_4_TITLE_2", index: 3 },
      { title: "STEP_5_TITLE_2", index: 4 },
      { title: "STEP_6_TITLE_2", index: 5 },
      { title: "STEP_7_TITLE_2", index: 6 },

      { title: "STEP_9_TITLE_2", index: 8 },
      { title: "STEP_8_TITLE_2", index: 7 },

      { title: "STEP_2_TITLE_2", index: 1 },
      { title: "STEP_3_TITLE_2", index: 2 },
      { title: "STEP_4_TITLE_2", index: 3 },
      { title: "STEP_5_TITLE_2", index: 4 },
      { title: "STEP_6_TITLE_2", index: 5 },
      { title: "STEP_7_TITLE_2", index: 6 },
      { title: "STEP_8_TITLE_2", index: 7 },

      { title: "STEP_2_TITLE_2", index: 1 },
      { title: "STEP_3_TITLE_2", index: 2 },
      { title: "STEP_4_TITLE_2", index: 3 },
      { title: "STEP_5_TITLE_2", index: 4 },
      { title: "STEP_6_TITLE_2", index: 5 },
      { title: "STEP_7_TITLE_2", index: 6 },

      { title: "STEP_9_TITLE_2", index: 8 },

      { title: "STEP_10_TITLE_2", index: 9 },
      { title: "STEP_11_TITLE_2", index: 10 },
    ],
  },
];

export const RAKAA_STEPS = [
  {
    image: step2S,
    title: "learn.SALAT.RAKAA.STEP_1_TITLE",
    description: "learn.SALAT.RAKAA.STEP_1_DESCRIPTION",
  },
  {
    image: step3S,
    title: "learn.SALAT.RAKAA.STEP_2_TITLE",
    description: "learn.SALAT.RAKAA.STEP_2_DESCRIPTION",
  },
  {
    image: step5S,
    title: "learn.SALAT.RAKAA.STEP_3_TITLE",
    description: "learn.SALAT.RAKAA.STEP_3_DESCRIPTION",
  },
  {
    image: step1S,
    title: "learn.SALAT.RAKAA.STEP_4_TITLE",
    description: "learn.SALAT.RAKAA.STEP_4_DESCRIPTION",
  },
  {
    image: step6S,
    title: "learn.SALAT.RAKAA.STEP_5_TITLE",
    description: "learn.SALAT.RAKAA.STEP_5_DESCRIPTION",
  },
  {
    image: step10S,
    title: "learn.SALAT.RAKAA.STEP_6_TITLE",
    description: "learn.SALAT.RAKAA.STEP_6_DESCRIPTION",
  },
  {
    image: step6S,
    title: "learn.SALAT.RAKAA.STEP_7_TITLE",
    description: "learn.SALAT.RAKAA.STEP_7_DESCRIPTION",
  },
  {
    image: step1S,
    title: "learn.SALAT.RAKAA.STEP_8_TITLE",
    description: "learn.SALAT.RAKAA.STEP_8_DESCRIPTION",
  },
  {
    image: step8S,
    title: "learn.SALAT.RAKAA.STEP_9_TITLE",
    description: "learn.SALAT.RAKAA.STEP_9_DESCRIPTION",
  },
  {
    image: step11S,
    title: "learn.SALAT.RAKAA.STEP_10_TITLE",
    description: "learn.SALAT.RAKAA.STEP_10_DESCRIPTION",
  },
  {
    image: step13S,
    title: "learn.SALAT.RAKAA.STEP_11_TITLE",
    description: "learn.SALAT.RAKAA.STEP_11_DESCRIPTION",
  },
];
