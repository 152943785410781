import axios from "axios";
import { GET_LIST_MOSQUES } from "../constants/apis.constants.ts";

export const getListMosques = async (
  position,
  radius,
  nextPageToken,
  modeTransport
) => {
  const response = await axios.get(GET_LIST_MOSQUES, {
    params: nextPageToken
      ? {
          pagetoken: nextPageToken,
        }
      : {
          latitude: position.latitude,
          longitude: position.longitude,
          radius,
        },
  });
  if (response?.data?.data?.results?.length) {
    const MatrixService = new window.google.maps.DistanceMatrixService();
    const resultGetDistance = await MatrixService.getDistanceMatrix({
      origins: [{ lat: position.latitude, lng: position.longitude }],
      destinations: response.data?.data?.results?.map(
        (mosque) => mosque.geometry.location
      ),
      travelMode: modeTransport,
      unitSystem: window.google.maps.UnitSystem.METRIC,
      avoidHighways: false,
      avoidTolls: false,
    });

    let result: any = null;
    if (resultGetDistance.rows && resultGetDistance.rows.length) {
      result = {
        nextPageToken: response.data?.data?.next_page_token || null,
        listMosques: response.data?.data?.results.map((mosque, index) => {
          return {
            name: mosque.name,
            location: mosque.geometry.location,
            photo: mosque.photos
              ? `https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photoreference=${mosque.photos[0].photo_reference}&key=AIzaSyATYxvLpHtisp-fr-1V-TjRnu7DHsAQJqc`
              : "mosque.svg",
            distance: resultGetDistance.rows[0].elements[index]?.distance,
            duration: resultGetDistance.rows[0].elements[index]?.duration,
            nextPageToken: response.data?.data?.next_page_token || "",
          };
        }),
      };
    } else {
      result = {
        nextPageToken: response.data?.data?.next_page_token || null,
        listMosques: response.data?.data?.results.map((mosque) => {
          return {
            name: mosque.name,
            location: mosque.geometry.location,
            photo: mosque.photos
              ? `https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photoreference=${mosque.photos[0].photo_reference}&key=AIzaSyATYxvLpHtisp-fr-1V-TjRnu7DHsAQJqc`
              : "mosque.svg",
          };
        }),
      };
    }
    return result;
  } else {
    return [];
  }
};
