import { createAsyncThunk } from "@reduxjs/toolkit";
import { getListMosques } from "../../services/googleMaps.service.ts";

export const fetchListMosques = createAsyncThunk(
  "fetchListMosques",
  async (params: {
    position: { lat: number; lng: number };
    radius: number;
    nextPageToken: string;
    modeTransport: string;
  }) => {
    const data = await getListMosques(
      params.position,
      params.radius,
      params.nextPageToken,
      params.modeTransport
    );
    return data;
  }
);
