import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ZakatPage.scss";
import { faAnglesRight, faAnglesLeft } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { createContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { LIST_POSSESSIONS_ZAKAT } from "../../constants/static_data.constants.ts";
import { useContext } from "react";
import { t } from "i18next";
import iconInformation from "../../assets/images/zakat/info.svg";
import { openDialog } from "../../features/general/generalSlice.ts";
import { useAppDispatch, useAppSelector } from "../../app/hooks.ts";
import Dialog from "../../components/common/dialog/Dialog.tsx";
import axios from "axios";
import { GET_PARAM_BY_NAME } from "../../constants/apis.constants.ts";

const ZakatContext = createContext();

const ZakatPage = () => {
  const { t, i18n } = useTranslation();
  const [numberSteps, setNumberSteps] = useState(0);
  const [step, setStep] = useState(0);
  const [listPossessionsChecked, setListPossessionsChecked] = useState([]);
  const dialog = useAppSelector((state) => state.generalSlice);
  const [nisabMoney, setNisabMoney] = useState(0);

  const handleNextStep = () => {
    if (step === 0) {
      if (!listPossessionsChecked.length) {
        toast.error(t("alert.POSSESSION_REQUIRED"));
      } else {
        setStep((currentStep) => currentStep + 1);
      }
    } else {
      let filledAmout = false;

      for (let j = 0; j < listPossessionsChecked[step - 1].list.length; j++) {
        if (listPossessionsChecked[step - 1].list[j].amount !== 0) {
          filledAmout = true;
          break;
        }
      }
      if (!filledAmout) {
        return toast.error(t("alert.ONE_REQUIRED"));
      }
      setStep((currentStep) => currentStep + 1);
    }
  };
  useEffect(() => {
    // Fetch the nisab value from the API
    axios
      .get(`${GET_PARAM_BY_NAME}nisab`)
      .then((response) => {
        setNisabMoney(response.data.data[0].value);
      })
      .catch((_error) => {
        toast.error(t("alert.ECHEC_SERVER"));
      });
  }, []);
  return (
    <ZakatContext.Provider
      value={{
        listPossessionsChecked,
        setListPossessionsChecked,
        setNumberSteps,
        setStep,
        nisabMoney,
      }}
    >
      {dialog.dialogOpened && dialog.nameDialog === "information-zakat" ? (
        <Dialog
          title={"Autoriser l'accès à votre position !"}
          description={
            "Muslim Community souhaite connaître votre position afin de partager des mises à jour pertinentes en temps réel"
          }
          secondaryButton={{ title: "OK" }}
        ></Dialog>
      ) : (
        <div className="pages-position">
          <div className="pages-header">
            <span className="title-page">{t("zakat.TITLE")}</span>
          </div>
          <div className="zakat-calculator-body">
            <div
              className="w-full p-4 min-700-screen:p-8 flex justify-center"
              style={{ minHeight: "50vh" }}
            >
              {step === 0 && <StepZero></StepZero>}
              {step > 0 && step <= numberSteps && (
                <StepPossession
                  possession={listPossessionsChecked[step - 1]}
                ></StepPossession>
              )}

              {step === numberSteps + 1 && <Result></Result>}
            </div>

            <div className="w-full flex justify-between">
              {step !== 0 ? (
                <div
                  className="cursor-pointer flex items-center"
                  onClick={() => setStep((currentStep) => currentStep - 1)}
                >
                  <FontAwesomeIcon icon={faAnglesLeft} className="icon" />
                  <span className="text-base xl:text-xl md:text-lg text-green font-bold">
                    {t("common.PREVIOUS")}
                  </span>
                </div>
              ) : (
                <div></div>
              )}
              {step <= numberSteps ? (
                <div
                  className="cursor-pointer flex items-center"
                  onClick={handleNextStep}
                >
                  <span className="text-base xl:text-xl md:text-lg text-green font-bold">
                    {step === 0
                      ? t("zakat.START")
                      : step === numberSteps
                      ? t("zakat.DONE")
                      : t("common.NEXT")}
                  </span>
                  <FontAwesomeIcon icon={faAnglesRight} className="icon" />
                </div>
              ) : (
                <div></div>
              )}
            </div>

            {/* stepper bar */}
            <div className="flex flex-col items-center">
              <span className="mb-2 font-bold">
                {t("common.STEP", {
                  step: step > numberSteps ? numberSteps : step,
                  totalSteps: numberSteps,
                })}
              </span>
              <input
                type="range"
                value={
                  numberSteps
                    ? step > numberSteps
                      ? 100
                      : (step / numberSteps) * 100
                    : 0
                }
                className={
                  "rounded-lg overflow-hidden appearance-none h-3 w-128 bar-stepper " +
                  (i18n.language === "ar" ? "arabic-bar" : "")
                }
              />
            </div>
          </div>
        </div>
      )}
    </ZakatContext.Provider>
  );
};

const StepZero = () => {
  const dispatch = useAppDispatch();

  const zakatContext = useContext(ZakatContext);
  const handleCheckPossession = (event, possession) => {
    const checked = event.target.checked;

    if (checked) {
      zakatContext.setNumberSteps((currentNumberStep) => currentNumberStep + 1);
      zakatContext.setListPossessionsChecked((currentList) =>
        currentList.concat([possession])
      );
    } else {
      zakatContext.setNumberSteps((currentNumberStep) => currentNumberStep - 1);
      zakatContext.setListPossessionsChecked((currentList) =>
        currentList.filter((el) => el.value !== possession.value)
      );
    }
  };

  const compareCheck = (list, el) => {
    return list.find((x) => x.value === el.value);
  };
  const viewDescription = (element) => {
    dispatch(
      openDialog({
        nameDialog: "information-zakat",
        description: element.description,
        title: element.name,
        image: element.image,
      })
    );
  };

  return (
    <div className="">
      {/* list possessions */}
      <div className="mb-8 flex flex-col items-center">
        <h1 className="mb-4">{t("zakat.I_HAVE")}</h1>
        <div className="px-2 min-700-screen:px-8">
          <ul className="font-medium rounded-lg">
            {LIST_POSSESSIONS_ZAKAT.map((el, index) => (
              <li className="" key={index}>
                <div className="flex items-center ps-3 justify-between">
                  <input
                    id={el.value}
                    type="checkbox"
                    value={el.value}
                    className="w-6 h-6 text-green bg-green border-green rounded focus:ring-green focus:ring-2 cursor-pointer accent-green"
                    onChange={(event) => handleCheckPossession(event, el)}
                    checked={compareCheck(
                      zakatContext.listPossessionsChecked,
                      el
                    )}
                  />
                  <label
                    htmlFor={el.value}
                    className="py-3 mx-4 font-medium cursor-pointer w-40"
                  >
                    {t(el.name)}
                  </label>
                  <img
                    src={iconInformation}
                    alt=""
                    className="icon-information"
                    onClick={() => viewDescription(el)}
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

const StepPossession = ({ possession }) => {
  const zakatContext = useContext(ZakatContext);
  const dispatch = useAppDispatch();

  const { t, i18n } = useTranslation();
  const changeValuePossession = (index, event) => {
    zakatContext.setListPossessionsChecked((Possessions) => {
      const possessionsUpdated = [...Possessions];

      const posessionChecked = possessionsUpdated.find(
        (el) => el.name === possession.name
      );
      if (posessionChecked.list[index]) {
        posessionChecked.list[index].amount = Number(event.target.value);
      }
      return possessionsUpdated;
    });
  };
  const viewDescription = (element) => {
    dispatch(
      openDialog({
        nameDialog: "information-zakat",
        description: element.description,
        title: element.name,
        image: element.image,
      })
    );
  };

  const handleTypeAgriculture = (indexAgriculture, type) => {
    zakatContext.setListPossessionsChecked((Possessions) => {
      const agriculture = Possessions.find((el) => el.value === "agriculture");

      const possessionsUpdated = [...Possessions];
      if (agriculture) {
        agriculture.list[indexAgriculture].type = type;
      }
      return possessionsUpdated;
    });
  };

  return (
    <div className="zakat-possession">
      <div className="mb-8">
        <h1 className="mb-2">{t(possession.name)}</h1>
        <div className="px-2 min-700-screen:px-8 min-700-screen:w-1/2">
          {possession.list.map((el, index) => (
            <div className="mb-2 min-700-screen:mb-4" key={index}>
              <h2>{t(el.name)}</h2>
              <div className="flex w-full possession items-center">
                <img src={el.image} alt="" className="icon-possession" />
                <span className="inline-flex items-center px-3 text-gray-900 bg-gray-200 border rounded-e-0 border-gray-300 border-e-0 rounded-s-md">
                  {possession?.unit}
                </span>
                <input
                  type="number"
                  min="0"
                  id={el.value}
                  className="rounded-none rounded-e-lg bg-gray-50 border text-gray-900 focus:ring-green focus:border-green block flex-1 text-sm border-gray-300 p-2 min-700-screen:p-2.5"
                  placeholder=""
                  value={el.amount}
                  onChange={(e) => changeValuePossession(index, e)}
                />
                {el.value === "or" && (
                  <div className="flex flex-row">
                    <span style={{ marginInline: "0.5em" }}>Karat</span>
                    <input
                      type="number"
                      placeholder="24"
                      className="rounded-none rounded-e-lg bg-gray-50 border text-gray-900 focus:ring-green focus:border-green block flex-1 text-sm border-gray-300 p-2 min-700-screen:p-2.5"
                    />
                  </div>
                )}
                {/* pluviale / irrigated */}
                {possession.value === "agriculture" && (
                  <div className="flex mx-2">
                    <div className="flex items-center me-4">
                      <input
                        id={"Irriguée" + el.value}
                        type="radio"
                        value="irrigated"
                        name={"inline-radio-group" + el.value}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 cursor-pointer"
                        onChange={() =>
                          handleTypeAgriculture(index, "irrigated")
                        }
                        checked={el.type === "irrigated"}
                      />
                      <label
                        for={"Irriguée" + el.value}
                        className="ms-2 text-sm font-medium text-gray-900 cursor-pointer"
                      >
                        {t("zakat.IRRIGATED")}
                      </label>
                    </div>
                    <div className="flex items-center me-4">
                      <input
                        id={"Pluviale" + el.value}
                        type="radio"
                        value="pluviale"
                        name={"inline-radio-group" + el.value}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 cursor-pointer"
                        onChange={() =>
                          handleTypeAgriculture(index, "pluviale")
                        }
                        checked={el.type === "pluviale"}
                      />
                      <label
                        for={"Pluviale" + el.value}
                        className="ms-2 text-sm font-medium text-gray-900 cursor-pointer"
                      >
                        {t("zakat.PLUVIALE")}
                      </label>
                    </div>
                  </div>
                )}
                <img
                  src={iconInformation}
                  alt=""
                  className="icon-information"
                  onClick={() => viewDescription(el)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div>
        {possession.value === "cache" && (
          <img style={{ width: "9em" }} src={possession.image} alt="" />
        )}
      </div>
    </div>
  );
};

const Result = () => {
  const zakatContext = useContext(ZakatContext);
  const { t } = useTranslation();
  const [possessionWithZakat, setPossessionWithZakat] = useState([]);

  const downloadZakatCalculator = () => {};

  const resetCalculator = () => {
    zakatContext.setNumberSteps(0);
    zakatContext.setStep(0);
    zakatContext.setListPossessionsChecked([]);
  };

  /******* calculate nisab ****/
  useEffect(() => {
    let moneyValue = 0;
    for (let possession of zakatContext.listPossessionsChecked) {
      possession.list.forEach((el) => {
        if (possession.value === "or_argent" && el.amount >= el.nisab) {
          // calculate nisab or and silver
          setPossessionWithZakat((current) => [
            ...current,
            {
              name: el.name,
              value: el.amount,
              nisab: `${el.nisab} ${possession.unit}`,
              zakat: `${el.amount * 0.025} ${possession.unit}`,
            },
          ]);
        } else if (possession.value === "cache") {
          moneyValue = moneyValue + el.amount;
        } else if (possession.value === "livestock") {
          // calculate nisab livestock
          if (el.value === "cattle") {
            if (el.amount >= 30) {
              let zakat = "";
              if (el.amount < 60) {
                zakat = "1 Veau";
              } else if (el.amount < 70) {
                zakat = "2 Veaux";
              } else if (el.amount < 80) {
                zakat = "1 Veau + 1 Vache";
              } else if (el.amount < 90) {
                zakat = "2 Veau + 1 Vache";
              } else {
                if (el.amount % 30 < 10) {
                  zakat = `${Math.trunc(el.amount / 30)} Veau`;
                } else if ((el.amount - 40) % 30 < 10) {
                  zakat = `${Math.trunc((el.amount - 40) / 30)} Veau + 1 Vache`;
                } else {
                  zakat = `${Math.trunc((el.amount - 80) / 30)} Veau + 2 Vache`;
                }
              }

              setPossessionWithZakat((current) => [
                ...current,
                {
                  name: el.name,
                  value: el.amount,
                  nisab: `${el.nisab}`,
                  zakat: zakat,
                },
              ]);
            }
          } else if (el.value === "sheep") {
            let zakat = "";
            if (el.amount >= 40) {
              if (el.amount < 121) {
                zakat = "1 Mouton";
              } else if (el.amount < 201) {
                zakat = "2 Moutons";
              } else if (el.amount < 400) {
                zakat = "3 Moutons";
              } else {
                zakat = `${Math.trunc(el.amount / 100)} Moutons`;
              }

              setPossessionWithZakat((current) => [
                ...current,
                {
                  name: el.name,
                  value: el.amount,
                  nisab: `${el.nisab}`,
                  zakat: zakat,
                },
              ]);
            }
          } else if (el.value === "camel") {
            let zakat = "";
            if (el.amount >= el.nisab) {
              if (el.amount < 25) {
                zakat = `${Math.trunc(el.amount / 5)} Moutons`;
              } else if (el.amount < 36) {
                zakat = "1 Chameau 1 à 2 ans";
              } else if (el.amount < 46) {
                zakat = "1 Chameau 2 à 3 ans";
              } else if (el.amount < 76) {
                zakat = "1 Chameau 4 à 5 ans";
              } else if (el.amount < 91) {
                zakat = "2 Chameaux 2 à 3 ans";
              } else if (el.amount < 121) {
                zakat = "2 Chameaux 3 à 4 ans";
              } else if (el.amount < 130) {
                zakat = "3 Chameaux 2 à 3 ans, ou 2 Chameaux 3 à 4 ans";
              } else {
                if (el.amount % 40 < 10) {
                  zakat = `${Math.trunc(el.amount / 40)} Chameaux 2 à 3 ans`;
                } else if ((el.amount - 50) % 40 < 10) {
                  zakat = `${Math.trunc(
                    (el.amount - 50) / 40
                  )} Chameaux 2 à 3 ans + 1 Chameau 3 à 4 ans`;
                } else if ((el.amount - 100) % 40 < 10) {
                  zakat = `${Math.trunc(
                    (el.amount - 100) / 40
                  )} Chameaux 2 à 3 ans + 2 Chameau 3 à 4 ans`;
                } else if ((el.amount - 150) % 40 < 10) {
                  zakat = `${Math.trunc(
                    (el.amount - 150) / 40
                  )} Chameaux 2 à 3 ans + 3 Chameau 3 à 4 ans`;
                } else {
                  zakat = `${Math.trunc(
                    (el.amount - 200) / 40
                  )} Chameaux 2 à 3 ans + 4 Chameau 3 à 4 ans`;
                }
              }

              setPossessionWithZakat((current) => [
                ...current,
                {
                  name: el.name,
                  value: el.amount,
                  nisab: `${el.nisab}`,
                  zakat: zakat,
                },
              ]);
            }
          }
        } else if (possession.value === "agriculture") {
          // calculate nisab agriculture
          if (el.amount >= el.nisab) {
            setPossessionWithZakat((current) => [
              ...current,
              {
                name: el.name,
                value: el.amount,
                nisab: `${el.nisab} ${possession.unit}`,
                zakat: `${el.amount * (el.type === "pluviale" ? 0.1 : 0.05)} ${
                  possession.unit
                }`,
              },
            ]);
          }
        }
      });
    }
    // calculate nisab money
    if (moneyValue >= zakatContext.nisabMoney) {
      setPossessionWithZakat((current) => [
        ...current,
        {
          name: "zakat.MONEY",
          value: moneyValue,
          nisab: zakatContext.nisabMoney + " " + "TND",
          zakat: `${moneyValue * 0.025} TND`,
        },
      ]);
    }
  }, []);

  return (
    <div className="">
      <div className="mb-8">
        <h1 className="mb-2">{t("zakat.RESULT")}</h1>
        <div className="">
          <p>
            Voici un résumé des informations que vous avez fournies et du
            montant de la Zakat que vous devez verser.
          </p>
          <div className="result-container">
            <div className="flex ">
              {possessionWithZakat?.map((el, index) => (
                <CardCalculator possession={el} key={index} />
              ))}
            </div>
            <div className="flex flex-row justify-evenly buttons">
              <button className="reset-btn" onClick={resetCalculator}>
                {t("zakat.RECALCULATE")}
              </button>
              {/* <button
                  className="download-btn"
                  onClick={downloadZakatCalculator}
                >
                  Télécharger
                </button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CardCalculator = ({ possession }) => {
  const { t } = useTranslation();

  return (
    <div className="result-section">
      <h2>{t(possession.name)}</h2>
      <div className="flex items-center justify-between">
        <h3>{t("zakat.I_HAVE")}</h3>
        <span>{possession.value}</span>
      </div>

      <div className="flex items-center justify-between text-redError font-semibold">
        <h3>{t("zakat.NISAB")}</h3>
        <span>{possession.nisab}</span>
      </div>

      <div className="flex items-center justify-between result">
        <h1>{t("zakat.ZAKAT")}</h1>
        <span>{possession.zakat}</span>
      </div>
    </div>
  );
};

export default ZakatPage;
