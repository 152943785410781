import axios from "axios";
import {
  ADD_CONTACT,
  GET_ALL_NAMES_OF_ALLAH,
} from "../constants/apis.constants.ts";
import {
  setErrorPosition,
  setPosition,
} from "../features/localisation/localisationSlice.ts";
import { closeDialog } from "../features/general/generalSlice.ts";

export const addContact = async (contact: {
  nom: string;
  prenom: string;
  email: string;
  numTel: string;
  description;
}) => {
  return axios.post(ADD_CONTACT, contact);
};

export const getAndSetLocalisation = (dispatch) => {
  // Check if geolocation is supported by the browser
  if ("geolocation" in navigator) {
    // Prompt user for permission to access their location
    navigator.permissions
      ?.query({ name: "geolocation" })
      .then((permissionStatus: any) => {
        getPosition(dispatch, permissionStatus.state);
        // if activate or desactivate localisation
        permissionStatus.onchange = () => {
          getPosition(dispatch, permissionStatus.state);
        };
      });
  } else {
    // Geolocation is not supported by the browser
    console.error("Geolocation is not supported by this browser.");
  }
};

const getPosition = (dispatch, state) => {
  if (state !== "denied") {
    navigator.geolocation.getCurrentPosition(
      // Success callback function
      (position) => {
        // save user location to the store
        dispatch(
          setPosition({
            latitude: position.coords?.latitude,
            longitude: position.coords?.longitude,
          })
        );
        dispatch(closeDialog());
      },
      // Error callback function
      (error) => {
        // Handle errors, e.g. user denied location sharing permissions
        console.error("Error getting user location:", error.message);
        dispatch(setErrorPosition(JSON.stringify(error.message)));
      }
    );
  } else {
    dispatch(setErrorPosition("Error getting user location"));
  }
};

export const getListNamesOfAllah = async () => {
  const response = await axios.get(`${GET_ALL_NAMES_OF_ALLAH}`, {});
  if (response) {
    return response?.data?.data;
  }
  return [];
};
